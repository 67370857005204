import React, {useState, useEffect} from 'react';

// import { Container } from './styles';
import './NewsletterSubscribe.css'

function Newsletter() {

    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState('');

   const t = (params) => params

   async function postEmail() {

    const requestOptions = {
       
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email })
    };
    fetch('https://api.datagro.com/api/user/subscribeNewsletter', requestOptions)
        .then(response => response.json())
        .then(data =>{
console.log(data)
setMsg(data.message)
        }  );
       
   }

  return (
    <div className="main"><div className="container">

    <div className="form">
        <h1 className="title formh1">RECEBA NOSSAS NOVIDADES </h1>
        <p className="formP"> Inscreva-se com seu email para receber nosso boletim de notícias</p>
        {/* <form onSubmit={this.onSubmit}> */}
       
            <fieldset >
                <input type='text' name='email' placeholder={'Cadastre seu email'} className="formInput" value={email}  onChange={(e)=> setEmail(e.target.value)}/>

                <button className="formB" onClick={()=> postEmail()}>Enviar</button> 


            </fieldset>
           {msg && <div className="success">{msg}</div>}
       
       {/* {this.state.error && <ErrorCard error={this.state.error} className="error" />}
        {this.state.successMessage && <div className="success">{this.state.successMessage}</div>}
         <Reaptcha
            ref={e => (this.captcha = e)}
            sitekey={appConfig.reCaptchSiteKey}
            explicit
            size='invisible'
            onVerify={() => this.onSubmit()}
        /> */}
    </div>

  

  

</div></div>
  )
}

export default Newsletter;