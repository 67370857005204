import React, {useEffect, useState} from 'react';

import  './News.css';

function News({page, qtt, }) {
    const [news, setNews] = useState([]);


    useEffect(() => {
        async function getNews() {
            let response = await fetch(`https://api.datagro.com/api/news/listmat?searchQuery=&marketId=5&sort=1&quantity=4&page=${page}&lang=pt-br`);
             

            let resJson = await response.json();
            
            setNews(resJson)
        }
        getNews()
    }, []);


  return (
    <div className='nList'>
         {news && news.map(function(n,i){
            return <Views news={n} />
         })}
      </div>
  );
}




function Views({news}) {

    let more =''
    if(news.description.length >120){
        more ='...'
    }

    let moreTitle =''
    if(news.title.length >100){
        moreTitle ='...'
    }

    return (
        <a href={`https://portal.datagro.com/${news.id}`} className='nLinkNews'>
        <div className='nNews'>
            <div style={{fontSize:14, height:70, fontWeight: 500}}> {news.title.substr(0, 100)} {moreTitle}</div>
            <div style={{display:'flex', flexDirection:'row', justifyContent: 'space-between'}}>
                <div>
                    <img src={news.imageUrl} width={80}  height={80}/>
                </div>
                <div style={{fontSize:'.75em',paddingLeft: 5}}>{news.description.substr(0, 120)} {more}</div>
            </div>    
        </div>
        </a>
    )

    
}



// marketId: otherProps.marketId,
// categoryId,
// sort,
// quantity: otherProps.max || 4,
// page:otherProps.page ||3, 


export default News;