import React, { useEffect } from 'react'

import { CSVLink, CSVDownload } from "react-csv";
import moment from 'moment'


import * as FileSaver from 'file-saver';
 import * as XLSX from 'xlsx/xlsx.mini';


export  function DownExcelFecfh({ quote = null, selectTime = null }) {
  
    const [data, setData] = React.useState("")
    const [load, setLoad] = React.useState(false)
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
  
    async function onclick(){
        console.log('onclique')
        let dataNow = moment().format('YYYY-MM-DD')
        let dataMin = moment().subtract(3, 'months').format('YYYY-MM-DD')

        switch (selectTime) {
            case '10D':
                dataMin = moment().subtract(10, 'days').format('YYYY-MM-DD')
                break
            case '1M':

                dataMin = moment().subtract(1, 'months').format('YYYY-MM-DD')
                break

            case '3M':

                dataMin = moment().subtract(3, 'months').format('YYYY-MM-DD')
                break
            case '6M':
                dataMin = moment().subtract(6, 'months').format('YYYY-MM-DD')

                break
            case '1Y':
                dataMin = moment().subtract(1, 'years').format('YYYY-MM-DD')

                break
            case '5Y':
                dataMin = moment().subtract(5, 'years').format('YYYY-MM-DD')
                break
                case '10Y':
                    dataMin = moment().subtract(10, 'years').format('YYYY-MM-DD')
                    break

        }


        


        let query = {
            'i': dataMin,
            'f': dataNow,
            'formato': 'json',
            'a': quote,
            'periodo': 'd'
        }

        function params(data) {
            return Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
          }

        let pa = params(query);
        let response = await fetch(`https://precos.api.datagro.com/dados/?idioma=pt-br&${pa}&cliente=a81574eb-06c1-11ed-bcba-02ebefa3b928`);
        console.log(response.body)
        let res = await response.json();

       function formatFloatLocale(val) {

   
            if (typeof val === 'number') {
                return val.toLocaleString()
            } else {
                try {
        
                    return new Intl.NumberFormat("pt-br").format(val)
        
                } catch (error) {
                    return val
                }
            }
        }
        

        if(res){

            var r = res
            var l = r.map(function (a) {
                let b = {}
                b.cod = a.cod
                b.dia = a.dia
                b.abe = a.abe != null ? formatFloatLocale(a.abe) : a.abe
                b.maxi = a.maxi != null ? formatFloatLocale(a.maxi) : a.maxi
                b.mini = a.mini != null ? formatFloatLocale(a.mini) : a.mini
                b.ult = a.ult != null ? formatFloatLocale(a.ult) : a.ult
                b.qtt = a.qtt != null ? formatFloatLocale(a.qtt) : a.qtt
                b.vtt = a.vtt != null ? formatFloatLocale(a.vtt) : a.vtt
                b.neg = a.neg != null ? formatFloatLocale(a.neg) : a.neg
                b.med = a.med != null ? formatFloatLocale(a.med) : a.med
                b.aju = a.aju != null ? formatFloatLocale(a.aju) : a.aju
                b.cab = a.cab != null ? formatFloatLocale(a.cab) : a.cab
                b.var = a.var != null ? formatFloatLocale(a.var) : a.var
                return b
            })



            let fileName = `datagro-${quote}`

            const ws = XLSX.utils.json_to_sheet(r);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            FileSaver.saveAs(data, fileName + fileExtension);

            
            //this.setState({ data: l, load: true, selectTime, quote })

        }



    }


    return (
        <div style={{marginBottom:20}}>
          <a style={{textDecoration: 'underline'}} onClick={()=> onclick()}>EXCEL</a>
        </div>
    )
}




export  function DownCsvFecfh({ quote = null, selectTime = null }) {
    const [data, setData] = React.useState("")
    const [load, setLoad] = React.useState(false)

    useEffect(() => {
     getData()
    }, [quote])
    

    console.log('dows csv', quote,selectTime )

    const getData =async()=> {
        let dataNow = moment().format('YYYY-MM-DD')
        let dataMin = moment().subtract(3, 'months').format('YYYY-MM-DD')
        switch (selectTime) {
            case '10D':

                dataMin = moment().subtract(10, 'days').format('YYYY-MM-DD')
                break
            case '1M':

                dataMin = moment().subtract(1, 'months').format('YYYY-MM-DD')
                break

            case '3M':

                dataMin = moment().subtract(3, 'months').format('YYYY-MM-DD')
                break
            case '6M':
                dataMin = moment().subtract(6, 'months').format('YYYY-MM-DD')

                break
            case '1Y':
                dataMin = moment().subtract(1, 'years').format('YYYY-MM-DD')

                break
            case '5Y':
                dataMin = moment().subtract(5, 'years').format('YYYY-MM-DD')
                break
                case '10Y':
                dataMin = moment().subtract(10, 'years').format('YYYY-MM-DD')
                break

        }



        let query = {
            'i': dataMin,
            'f': dataNow,
            'formato': 'json',
            'a': quote,
            'periodo': 'd'
        }

        function params(data) {
            return Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
          }

        let pa = params(query);
        let response = await fetch(`https://precos.api.datagro.com/dados/?idioma=pt-br&${pa}&cliente=a81574eb-06c1-11ed-bcba-02ebefa3b928`);
        console.log(response.body)
        let res = await response.json();



        if(res)  {
            console.log(res)
            var r = res
           // var test = obj2csv(r, {})
            setData(res)
            setLoad(true)

            //this.setState({ data: l, load: true, selectTime, quote })

        }





    }



    return (
        <div>
          <CSVLink
             data={data} 
      asyncOnClick={true}
      onClick={()=>getData()}
      filename={`Datagro-${quote}.csv`} separator={"|"} 
    > CSV</CSVLink>
        </div>
    )
}






// export function DownCsvData({ data }) {
//     let user = new User()

//     var test = obj2csv(data, {})




//     if(user.relation === "D") return   <div style={{marginBottom:20}}></div>

//     return (
//         <div>
//             {data && <CSVLink data={test} filename='Datagro-lineup.csv' header={test} separator={"|"} > {t('downloadData')}</CSVLink>}
//         </div>
//     )
// }

// // data:text/csv;charset=utf-8,sep=%7C%0A0%7C2015%7C2016%7C2017%7C2018%7C2019%7C2020%0ACorn%7C30.707.726%7C16.506.616%7C38.997.327%7C21.550.795%7C41.323.620%7C1.928.077%0AEthanol%7C1.973.960%7C1.163.426%7C1.308.660%7C1.280.389%7C1.276.184%7C236.858%0ASoybean%7C70.140.158%7C64.543.917%7C99.699.790%7C98.204.700%7C86.447.106%7C51.615.170%0ASugar%7C25.025.652%7C27.357.403%7C27.505.570%7C20.352.689%7C17.692.484%7C7.252.925


// // data:text/csv;charset=utf-8,set=;%0A0;2015;2016;2017;2018;2019;2020%0ACorn;30.707.726;16.506.616;38.997.327;21.550.795;41.323.620;1.928.077%0AEthanol;1.973.960;1.163.426;1.308.660;1.280.389;1.276.184;236.858%0ASoybean;70.140.158;64.543.917;99.699.790;98.204.700;86.447.106;51.615.170%0ASugar;25.025.652;27.357.403;27.505.570;20.352.689;17.692.484;7.252.925



// function obj2csv(obj, opt) {
// try {
//     console.log(obj, opt)
//     if (typeof obj !== 'object') return null;
//     console.log('passou')
//     opt = opt || {};
//     var scopechar = opt.scopechar || '/';
//     var delimeter = opt.delimeter || '|';
//     if (Array.isArray(obj) === false) obj = [obj];
//     var curs, name, rownum, key, queue, values = [], rows = [], headers = {}, headersArr = [];
//     for (rownum = 0; rownum < obj.length; rownum++) {
//         queue = [obj[rownum], ''];
//         console.log(obj[rownum])
//         rows[rownum] = {};
//         while (queue.length > 0) {
//             console.log(queue.length)
//             name = queue.pop();
//             curs = queue.pop();
//             if (curs !== null && typeof curs === 'object') {
//                 for (key in curs) {
//                     if (curs.hasOwnProperty(key)) {
//                         queue.push(curs[key]);
//                         queue.push(name + (name ? scopechar : '') + key);
//                     }
//                 }
//             } else {
//                 if (headers[name] === undefined) headers[name] = true;
//                 rows[rownum][name] = curs;
//             }
//         }
//         values[rownum] = [];
//     }
//     // create csv text
//     for (key in headers) {
//         if (headers.hasOwnProperty(key)) {
//             headersArr.push(key);
//             for (rownum = 0; rownum < obj.length; rownum++) {
//                 values[rownum].push(rows[rownum][key] === undefined
//                     ? ''
//                     : JSON.stringify(rows[rownum][key]));
//             }
//         }
//     }
//     for (rownum = 0; rownum < obj.length; rownum++) {
//         values[rownum] = values[rownum].join(delimeter);
//     }
//     return '"sep=|"\n"' + headersArr.join('"' + delimeter + '"') + '"\n' + values.join('\n');

// } catch (error) {
//     console.log(error)
// }
// }