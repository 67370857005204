import React from 'react'
import './header.css'




function Header({title, color, width, height}) {
    return (
        <div className="widgetHeader" style={{backgroundColor: `${color}`}} >
            <div className="widgetHeaderTitle" >{height}</div>
            <div className="widgetHeaderTitle"> {title}</div>
            <div className="widgetHeaderChild"> {width}</div>
        </div>
    )
}

export default Header
