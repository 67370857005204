import React, { useState, useEffect } from 'react'
import { useAuth } from "../../contexts/AuthContext"
import api from '../../Services/Api'
import { Form, Button, InputGroup, Col, FloatingLabel, Row, Container, Alert, Image } from 'react-bootstrap'
import { Confirm, CustomDialog, useDialog, StaticDialog } from 'react-st-modal';
import Loading from '../../Components/Widgets/Loading'

import './../Signup/Plan.css'
import imaPlan from './../../Assets/icons/register_plans.png'
import imaTag from './../../Assets/images/tag-alt.svg'

import { AiOutlineCheck , AiOutlineClose, AiOutlineConsoleSql} from "react-icons/ai";
import {
  
  Link,useHistory
     
   } from "react-router-dom";

export default function Plans() {

    const { currentUser, info } = useAuth()
    const [plan, setPlan] = useState({})
    const [loading, setloading] = useState(true)
const { push } = useHistory()

// push('/signup')
    useEffect(() => {
      console.log(info, currentUser)
        getPlan()
    }, [info])



    const getPlan = () =>{
      try {

        function b_PlanUser (i) {
          if ( i.id === info.activeSubscriptionId) 
          return i;
         }
   
         let planUser = info.subscriptions.filter(b_PlanUser)

        api
        .get(`/user-api/plans/${planUser[0].planId}`)
        .then((response) => {
            setPlan(response.data.data)
            console.log(response.data.data, info.activeSubscriptionId)
            setloading(false)
           
        })
        .catch((err) => {
            console.log("ops! ocorreu um erro" + err);
        });
      } catch (error) {
        console.log(error)
      }
           
    }


    if(loading) return <Loading/>
  
    return (
    <div className="properties">
         <div style={{fontSize: 30, fontWeight: 'bold', margin: 30}}> 
                Meu Plano
            </div>
            {plan && <div className="planWidget" tabIndex={0}>
          <div className="planHeader">
            <div className="planIconPlan" style={{ backgroundImage: `url(${imaPlan}` }}>
              <div className="planIconPlanText">{plan.name}</div>
            </div>
            <div className="planHeaderTitle">
              <div className="planHeaderPrice">{plan.exhibitionPrice}</div>
              <p className="planHeaderDiscount">{plan.discount}</p>
            </div>
          </div>

          <div className="planDescription">
            {plan.description}
          </div>

          <div className="planTitleBenefits">Conteúdo Disponível:</div>

          <div className="planBenefits">
            {plan.benefits.map((b, e) => <div className="planBenefitsItem">
              <div className="planBenefitsItemTitle">{b.description}</div>
              <div className="planBenefitsItemIcon">{b.status ? <AiOutlineCheck style={{color: "green"}}/> : <AiOutlineClose style={{color: "red"}} />}</div>
            </div>
            )}

          </div>
          

        </div>
      }

<Button variant="success"
          style={{ color: "white", background: "#ccc", width: 200}} onClick={()=> push('/app/alter', {planid : info.subscriptions[0].planId})}
        >
          Alterar Plano
        </Button>

      


        </div>
    )
}
