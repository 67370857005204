
import React from 'react'
// import t from 'ui-stack/lang'
// import { createLineBreak } from 'ui-stack/util/text'
// import Icon from 'ui-stack/ui/Icon'

// import contacts from '../../content/contacts'
// import url from '../../util/url'

// import { ButtonLink } from '../Helpers/Link'
// import Clear from '../Helpers/Clear'

import './FooterAbout.css'

function t(params) {
    return params
}


// const ContactIcon = ({ name }) => {
//     return <Icon name={name} stack='circle' className={styles.icon} />
// }

const FooterAbout = (config) => {



    return (
        <div className={'fmain'}><div className={'fcontainer'}>

            <div className={'ftext'} style={{marginLeft:10}}>
                <h1 className={'ftitle'}>Sobre a DATAGRO</h1>
                <p >A DATAGRO é uma consultoria agrícola independente, que produz análises e dados primários sobre as principais commodities agrícolas. Com mais de 35 anos de experiência, analisa os mercados de açúcar, etanol, energia, milho, soja, carnes, e suas relações com a economia como um todo.</p>
                <p>
                    {/* <ButtonLink to={url.about()} className={styles.knowMoreLink}>{t('knowMore')}</ButtonLink>
                    <ButtonLink to={url.terms()} className={styles.useTermsLink}>{t('useTerms')}</ButtonLink> */}
                </p>
            </div>

            <div className={'fcontacts'}>
                <h1 className={'ftitle'}>DATAGRO nas redes sociais</h1>

                <div> {config.config.networks.map((item) => <a href={item.url} target='_blank' class={`fa fa-${item.icon}`}></a>)}</div>
               
                    {/* {contacts().map(link => {

                        const {
                            icon,
                            ...linkProps
                        } = link

                        return <ButtonLink className={styles.iconLink} title={t(link.key)} {...linkProps}><ContactIcon name={icon || link.key} /></ButtonLink>
                    })} */}
                
                <p className={'faddress'}>
                    <p >Calçada das Magnólias, 56 - Centro Comercial Alphaville<br />Barueri - SP - Brasil<br />CEP 06453-032<br /></p>
                    <i class="fa fa-phone " aria-hidden="true"></i> +55 (11) 4133-3944
                </p>
            </div>


        </div></div>
    )
}

export default FooterAbout
