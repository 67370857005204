import React, { useState, useEffect }from 'react'
import { useAuth } from "../../contexts/AuthContext"
import Button from 'react-bootstrap/Button'
import './Index.scss'
import { Link, useHistory } from "react-router-dom"
import { AiOutlineBarcode, AiFillCreditCard  } from "react-icons/ai";
export default function Checkout({next}) {
    const { currentUser, addUser, splan, setPlan } = useAuth()
    const [methods, setMethods] = useState(false)
    const [dados, setDados] = useState({})
    const history = useHistory()

    useEffect(() => {
        GetInfo()
    }, [])

   async function GetInfo(){
    
    let plans =  await JSON.parse(localStorage.getItem('PLAN'));
    let terms = await JSON.parse(localStorage.getItem('TERMS'));

    setDados({'user':currentUser.user, 'plans': plans, 'terms':terms})

    var planType = plans.plan?.type ?? "";
    switch (planType) {
      case 'free':
       console.log('free:',planType, splan )
       history.push("/")
        break;
      case 'cupom':
       console.log('cupom:',planType, splan )
        history.push("/properties")
        break;
      default:
          console.log('outros:',planType, splan )
        // Modular.to.popAndPushNamed('/signup/payment-methods');
        break;
    }


    setMethods(true)
    }


    const BtnClick = (type) =>{

        let p =  splan
        p['methods']= type
        setPlan(p)
       
        history.push("/app/address")

    }

    function Methods() {
        return (
            <div className="signup-content">
                <div className="signup-title">
                    Selecione o metado de pagamento
                </div>
                <div className="methods">
                    <div onClick={()=>BtnClick('cartao')} className="methods-box"> <AiFillCreditCard/> Cartão de Crédito</div>
                  { (dados.plans?.plan?.type  ?? "") != 'collaborator' && <div onClick={()=>BtnClick('boleto')} className="methods-box"> <AiOutlineBarcode /> Boleto Bancário</div> }    
                </div>   
            </div>
        )
    }



    if(methods) return <Methods />



    return (
        <div>
           Carregando
        </div>
    )
}



