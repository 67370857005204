import React, {useState, useEffect} from 'react';
import useFetch from 'react-fetch-hook';

import Routes from "./routes";
import TopBar from './Components/Header/TopBar'
import Quote from './Components/Quote/BarQuote.jsx'
import Header from './Components/Header/Header.jsx'
import Banner from './Components/Header/Banner'
import axios from "axios";

import BannerFooter from './Components/Header/BannerFooter'
import NewsLetter from './Components/Footer/Newsletter'
import Disclaimer from './Components/Footer/Disclaimer'
import FooterAbout from './Components/Footer/FooterAbout'

import { UtilsProvider } from './Components/Hooks/SelectChart'
import 'bootstrap/dist/css/bootstrap.min.css';
import './cbioSantander.css';
import {  BrowserRouter } from "react-router-dom";


export  function App({config}) {
   
   return (
    <BrowserRouter basename='/' >
    <UtilsProvider>
      
         <div className="body"  >
        <TopBar config={config} />
        <div className="cabecario" >
          <div className="cabecarioLogos">
            <Header config={config} />
            <hr style={{ marginTop: -10 }} className="hrLine" />
            <Quote config={config} /> 
          </div>
          
          <div className="cBanner" >
              <Banner config={config} /> 
          </div>
        </div>
        
        <Routes/> 
       
        <BannerFooter config={config}/>
        <NewsLetter />
        <FooterAbout config={config}/>
        <Disclaimer config={config} />
       </div>
    </UtilsProvider>
    </BrowserRouter>
   );
}



const AutoLoadQuote = ({ location, match }) => {
    const [dados, setDados] = useState()
    const [loading, setLoading] = useState(true)
    const [config, setConfig] = useState()
    const [isClicked, setIsClicked] = useState(false);
    const [message, setMessage] = useState('');
  
    const { isLoading, data } = useFetch(
      'https://portal.datagro.com/gpb/config/config.php?type=config',
       { depends: [isClicked] }
      );

   useEffect(() => {
     setIsClicked(true);
 },[]);

 useEffect(() => {
    if (isLoading) {
       setMessage('Loading ...');
    }
    if (data && !isLoading) {
      setConfig(data);
      setLoading(false)
    }
 },[data, isLoading])

  
    if (!loading)
      return <App config={config} />
  
    return (
      <h1 ></h1>
    )
  }
  
  export default AutoLoadQuote;