import React, { useState, useRef, useCallback, useEffect } from 'react';
import CForm from './../../Components/form';
import Card from './../../Components/card';
import {  Alert, Button } from 'react-bootstrap'
import api from './../../Services/Api'
import { useAuth } from "../../contexts/AuthContext"
import './Card.scss';
import { Link, useHistory } from "react-router-dom"



const initialState = {
    cardNumber: '#### #### #### ####',
    cardHolder: 'NOME COMPLETO',
    cardMonth: '',
    cardYear: '',
    cardCvv: '',
    isCardFlipped: false,
    
};

const MainScreen = ({next}) => {
    const [state, setState] = useState(initialState);
    const { currentUser, addUser, splan } = useAuth()
    const [btn, setBtn] = useState(true)
    const [currentFocusedElm, setCurrentFocusedElm] = useState(null);
    const [error, setError] = useState(false)

    const history = useHistory()

    useEffect(() => {
      if(splan.methods == "boleto") history.push("/app/checkout")
    }, [])

    const updateStateValues = useCallback(
        (keyName, value) => {
            setState({
                ...state,
                [keyName]: value || initialState[keyName]
            });
        },
        [state]
    );


        useEffect(() => {
            console.log(state)
            const infoState = () =>{
                setBtn(false)

                if(!state.cardNumber) setBtn(true) 
                if(!state.cardHolder) setBtn(true)
                if(!state.cardMonth) setBtn(true)
                if(!state.cardYear) setBtn(true)
                if(!state.cardCvv) setBtn(true)

              


            }
            infoState()
        }, [state])


    // References for the Form Inputs used to focus corresponding inputs.
    let formFieldsRefObj = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef(),
        cardCvv: useRef()
    };

    let focusFormFieldByKey = useCallback((key) => {
        formFieldsRefObj[key].current.focus();
    });

    // This are the references for the Card DIV elements.
    let cardElementsRef = {
        cardNumber: useRef(),
        cardHolder: useRef(),
        cardDate: useRef()
    };

    let onCardFormInputFocus = (_event, inputName) => {
        const refByName = cardElementsRef[inputName];
        setCurrentFocusedElm(refByName);
    };

    let onCardInputBlur = useCallback(() => {
        setCurrentFocusedElm(null);
    }, []);

    const onclickBtn =()=>{
        let number = state.cardNumber.split(' ').join('');
        console.log(number)
        var data = {
            "userId": currentUser.user.id,
            "number": number,
            "holderName": state.cardHolder,
            "expirationDate": `${state.cardMonth}/${state.cardYear}`,
            "cvv": state.cardCvv,
          };
          
          console.log(data)

          api
          .post(`/user-api/cards`, data )
          .then((response) => {
              console.log(response.data.data)
              localStorage.setItem('PAG', JSON.stringify(response.data.data)); 
              history.push("/app/checkout")    
          })
          .catch((err) => {
              setError(true)
              console.log("ops! ocorreu um erro" + err);
          });
        console.log('btnclin')
    }

    function removeMask({number = ""}) {
        return number.replaceAll(RegExp('[^0-9]'), '');
      }

    return (
        <div className="signup-content">
        <div className="signup-title">
            Dados do Cartão
        </div>
       {error &&  <Alert  variant="danger">
        Ocorreu um erro ao validar seu método de pagamento
        </Alert>}
        <div className="wrapper" style={{justifyContent: 'center', alignContent: 'center' }} >
            <CForm
                cardMonth={state.cardMonth}
                cardYear={state.cardYear}
                onUpdateState={updateStateValues}
                cardNumberRef={formFieldsRefObj.cardNumber}
                cardHolderRef={formFieldsRefObj.cardHolder}
                cardDateRef={formFieldsRefObj.cardDate}
                onCardInputFocus={onCardFormInputFocus}
                onCardInputBlur={onCardInputBlur}
                activeBtn={btn}
                onclickBtn={onclickBtn}
            >
                
                <Card
                    cardNumber={state.cardNumber}
                    cardHolder={state.cardHolder}
                    cardMonth={state.cardMonth}
                    cardYear={state.cardYear}
                    cardCvv={state.cardCvv}
                    isCardFlipped={state.isCardFlipped}
                    currentFocusedElm={currentFocusedElm}
                    onCardElementClick={focusFormFieldByKey}
                    cardNumberRef={cardElementsRef.cardNumber}
                    cardHolderRef={cardElementsRef.cardHolder}
                    cardDateRef={cardElementsRef.cardDate}
                >
                     
                </Card>
                
              
            </CForm>
          
        </div>
        </div>
    );
};

export default MainScreen;
