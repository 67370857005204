import React, {useState, useEffect} from 'react';

// import { Container } from './styles';
import GraficoAny from '../Chart/index.jsx'
import {useUtils} from './../Hooks/SelectChart'


function Chart({ativo, id}) {

    console.log('-------------------------')
    console.log('-------------------------',ativo,id)


    const {selectQuote, setSelectQuote} = useUtils()


    const [quote, setQuote] = useState(false);


    useEffect(() => {
        setQuote(false)

        async function getNews() {
            let response = await fetch(`https://precos.api.datagro.com/dados/?idioma=pt-br&a=${ativo}&formato=json&cliente=a81574eb-06c1-11ed-bcba-02ebefa3b928`);
         console.log(response)

            let resJson = await response.json();
            console.log('Chart:',resJson);
            setQuote(resJson)
        }
        if(ativo) getNews()
    }, [ativo]);

  if(!quote) return <div />;
  if(quote) return <GraficoAny  dados={quote[0]} id={id}/>;


}

export default Chart;