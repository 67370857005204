import React, { useState, useEffect } from 'react'
import { useAuth } from "../../contexts/AuthContext"
import { Form, Button, Image, Col, FloatingLabel, Row, Container } from 'react-bootstrap'
import { Confirm, CustomDialog, useDialog, StaticDialog } from 'react-st-modal';
import { Link, useHistory } from "react-router-dom"
import {isMobile} from 'react-device-detect';
import './Index.scss'
import api from './../../Services/Api'
import imgCoin from './../../Assets/images/coin.svg'

const ticketTextCheckout =
    "Usaremos o cpf fornecido para gerar o seu boleto e dar prosseguimento em seu processo de pagamento.\n\nEnquanto seu pagamento não é identificado você terá acesso completo ao plano contratado. Caso dentro de 3(três) dias o pagamento não for identificado, você será automaticamente transferido para o plano gratuito.";
const cardTextCheckout =
    "Ao clicar, suas informações serão transmitidas de forma segura para nossa plataforma de pagamento, Pagar.me. A cobrança será feita de forma recorrente na mesma data de contratação, até que seja feito o seu cancelamento.";


export default function Checkout({ next }) {
    const { currentUser, splan } = useAuth()
    const [plan, setPlan] = useState()
    const [p, setP] = useState({})
    const [transition, setTransition] = useState(false)
    const [res, setRes] = useState()
    const [isOpen, setOpen] = useState(false);
   

    const { push } = useHistory()
    const [url, setUrl] = useState('')
    useEffect(() => {
        GetInfo()
    }, [currentUser])


    async function GetInfo() {
        console.log('user', currentUser.user, )


        if(splan.methods)
    
        setPlan(splan)
        console.log('plans:', splan)


        setP(splan.ticketplan ? splan.ticketplan : splan.plan)
        console.log(splan.ticketplan , splan.ticketplan , splan.plan )
       

    }




    const handleBnt = () => {

        var data = {
            "userId": currentUser.user.id,
            "planId": splan.ticketplan ? splan.ticketplan.ticketPlanId : splan.plan.id ,
            "ticketId": '',
            "paymentMethod": splan.methods == "cartao" ? "credit_card" : "boleto"
        };

        console.log(data)

        if(splan.new){

            api
            .post(`/user-api/subscriptions`, data)
            .then((response) => {
                setRes(response.data.data)
                localStorage.setItem('CHECKOUT', JSON.stringify(response.data.data));
                if (response.data?.data?.boletoUrl) openMyWindow(response.data?.data?.boletoUrl)
                setOpen(false);
                push("/properties")


            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
                setOpen(false);
            });


        }else{
            api
            .put(`/user-api/subscriptions`, data)
            .then((response) => {
                setRes(response.data.data)
                localStorage.setItem('CHECKOUT', JSON.stringify(response.data.data));
                if (response.data?.data?.boletoUrl) openMyWindow(response.data?.data?.boletoUrl)
                else{
                    console.log(response.data.data)
                    fin()
                }

            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
                setOpen(false);
            });
        }
     
    }

    const openMyWindow = (url) => {
        console.log(url)
        setUrl(url)
        setOpen(true)
        // history.push("/app/plan")
        // const newWindow = window.open(url, '_blank').focus()
        // if (newWindow) newWindow.opener = null

    }



   async function fin(){
      
            push('/app/plan')
        
      
    }


    if (!plan) return <div>Carregando</div>

    return (
        <div className="signup-content">
            <div className="signup-title">
                Pagamento
            </div>
            <div className="checkout-box">

               
                <div> <b>Você está contratando:</b>  {p.pname}</div>
                <div> <b>Valor:</b> {p.ticketplan.price ? p.ticketplan.price : p.plan.price}</div>

                <div> <b>Forma de Pagamento:</b> {plan.methods === "cartao"
                    ? 'Assinatura'
                    : 'Pagamento Único'}</div>

<div> <b>Modalidade:</b> {plan.methods === "cartao"
                    ? 'Cartão de Credito'
                    : 'Boleto Bancário'}</div>
 
 
 <div className="checkout-modal">
                    {plan.methods === "cartao"
                        ? ticketTextCheckout
                        : cardTextCheckout}
                </div>


<Button type="button" className="btn-dl-green" onClick={() => handleBnt(true)}>{plan.methods === "cartao"
                ? 'Efetuar Pagamento'
                : 'Gerar Boleto'}</Button>

            </div>

           
            {/* {currentUser.user.methods !== "cartao" && res
                        

                    <button  onclick=" window.open('http://google.com','_blank')"> Google</button>
                } */}


            <StaticDialog
                isOpen={isOpen}
                title=""
                className="CustomDialog"
                onAfterClose={(result) => {
                    setOpen(false);

                    // do something with dialog result
                }}
            >
                {/* see previous demo */}
                <div className="checkout-modal">
                    {plan.methods === "cartao"
                        ? ticketTextCheckout
                        : cardTextCheckout}

                    <Button type="button" className="btn-dl-green" onClick={() =>{
                        // window.open(url,'_blank');
                        fin()

                    }}>{plan.methods === "cartao"
                        ? 'Efetuar Pagamento'
                        : 'Gerar Boleto'}</Button>
                </div>
            </StaticDialog>


        </div>
       
    )
}
