import React, { useState, useEffect } from 'react';
import Quadro from '../Components/Quote/Quadro.jsx'
import WidgetsList from '../Components/Widgets/List.jsx'
import News from '../Components/News/News'
import User from './User'
import useFetch from 'react-fetch-hook';

function Home({ config }) {
  return (
      <div  >
        <User />
        <WidgetsList />
        {config && config.minihome.map((a, i)=>{
          console.log(i)
          return   <Quadro id={i+1} data={a}/>

        })}
        <hr style={{ marginTop: -10 }} />
        <News page={0} />
        <hr style={{ marginTop: -10 }} />
        <News page={1} />
      </div>
 
  )
}




const AutoLoadQuote = ({ location, match }) => {
  const [dados, setDados] = useState()
  const [loading, setLoading] = useState(true)
  const [config, setConfig] = useState()
  const [isClicked, setIsClicked] = useState(false);
  const [message, setMessage] = useState('');

  const { isLoading, data } = useFetch(
    'https://portal.datagro.com/gpb/config/config.php?type=config',
     { depends: [isClicked] }
    );

 useEffect(() => {
   setIsClicked(true);  
},[]);

useEffect(() => {
  if (isLoading) {
     setMessage('Loading ...');
  }
  if (data && !isLoading) {
    setConfig(data);
    setLoading(false)
  }
},[data, isLoading])


  if (!loading)
    return <Home config={config} />

  return (
    <h1 >Carregando</h1>
  )
}

export default AutoLoadQuote;