import React,{useState, useEffect} from 'react';
import Modal from 'react-modal';
import Image from 'react-bootstrap/Image'
import useFetch from 'react-fetch-hook';
import './List.css'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1200: { items: 3 },
};

function List() {
  return (
    <div className="cWidgetsList">
         <Maps />
        <Youtube />
      </div>
  )
}


function Maps(){

const [maps, setMaps] = useState([]);

const [isClicked, setIsClicked] = useState(false);
const [message, setMessage] = useState('');

const { isLoading, data } = useFetch(
  'https://portal.datagro.com/gpb/config/config.php?type=maps',
   { depends: [isClicked] }
  );

useEffect(() => {
 setIsClicked(true);
},[]);

useEffect(() => {
if (isLoading) {
   setMessage('Loading ...');
}
if (data && !isLoading) {

  let arr =[];
 data.map(function(a,i){
    arr.push(<ViewMap link ={a} key={i}  data-value={i + 1}/>)
 })

 console.log(arr)
 setMaps(arr);
}
},[data, isLoading])

if(isLoading) return <div></div>

  return<div className="imgMaps">
      <AliceCarousel
        mouseTracking
        items={maps}
        responsive={responsive}
        disableDotsControls
        controlsStrategy="responsive"
    />
  </div>
  
}


function ViewMap (link){
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
  }

  return(

       <div className="imgMap" >
            <div  onClick={openModal}>
              <Image src={link.link.img}  className="img-map" />
            </div>

            <Modal
        isOpen={modalIsOpen}
        
        onRequestClose={closeModal}
      
      >
        
        <button onClick={closeModal} className='btnModal'>Fechar</button>
        <Image src={link.link.img}  className="img-map" />
      </Modal>
            </div>
  
  ) 

}

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)


function Youtube() {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [video, setVideo] = useState({});


  const [isClicked, setIsClicked] = useState(false);
    const [message, setMessage] = useState('');
  
    const { isLoading, data } = useFetch(
      'https://portal.datagro.com/gpb/config/config.php?type=video',
       { depends: [isClicked] }
      );

   useEffect(() => {
     setIsClicked(true);
 },[]);

 useEffect(() => {
    if (isLoading) {
       setMessage('Loading ...');
    }
    if (data && !isLoading) {
      setVideo(data);
    
    }
 },[data, isLoading])
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {

  }

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

if(!video.urlVideo) return null;

  return (
    <div>
      {/* <button onClick={openModal}></button> */}
      <div className="video-minuto">
       
        <Image src={video.image}  className="img-video"  onClick={openModal}/>
        <div className="play-icon" onClick={openModal}> <i class="fas fa-play-circle" ></i></div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
       
        <button onClick={closeModal} style={{top:0, position:'absolute', right:0}}>x</button>
        <iframe id="ytplayer" type="text/html" width="640" height="360"
  src={video.urlVideo}
  frameborder="0"/>
       
      </Modal>
    </div>
  );
}




export default List;