
import React, { useState, useEffect } from 'react'

import { Form, Button, InputGroup, Col, FloatingLabel, Row, Container, Offcanvas, ProgressBar, Image } from 'react-bootstrap'

import { useAuth } from "../contexts/AuthContext"
import api from '../Services/Api'
import '../cbioSantander.css';
import Multiselect from 'multiselect-react-dropdown';

import imgLoader from './../Assets/animations/truck_loading.json'
import moment from 'moment';
import Lottie from 'react-lottie';

const vaca =
    "https://firebasestorage.googleapis.com/v0/b/balizador.appspot.com/o/images%2Fcows%2Fvaca.png?alt=media&token=44c4d6fd-52c4-4cb8-b99e-441809435b98";
const touro =
    "https://firebasestorage.googleapis.com/v0/b/balizador.appspot.com/o/images%2Fcows%2Ftouro.png?alt=media&token=6ba5d870-e668-430c-abb2-caaec6b61e28";
const novilha =
    "https://firebasestorage.googleapis.com/v0/b/balizador.appspot.com/o/images%2Fcows%2Fnovilha.png?alt=media&token=15d08873-95ff-4fab-bacd-0150e6393ec4";
const bezerro =
    "https://firebasestorage.googleapis.com/v0/b/balizador.appspot.com/o/images%2Fcows%2Fbezerro.png?alt=media&token=cc1f0018-4b65-4749-9a46-f252d628447e";
const bezerroMacho =
    "https://firebasestorage.googleapis.com/v0/b/balizador.appspot.com/o/images%2Fcows%2Fbezerro_macho.png?alt=media&token=862b6f9f-90e9-4a30-856f-df824832f34c";
const bezerroFemea =
    "https://firebasestorage.googleapis.com/v0/b/balizador.appspot.com/o/images%2Fcows%2Fbezerro_femea.png?alt=media&token=0e102a80-0c57-4b78-80f1-0e4a7b817b1f";
const garrote =
    "https://firebasestorage.googleapis.com/v0/b/balizador.appspot.com/o/images%2Fcows%2Fgarrote.png?alt=media&token=9eefb80a-7c4a-436e-8eb2-b1bdd9e04cd0";
const novilha12 =
    "https://firebasestorage.googleapis.com/v0/b/balizador.appspot.com/o/images%2Fcows%2Fnovilha_12.png?alt=media&token=3f01c80e-78b0-498b-9312-a9decc85c188";
const boiMagro =
    "https://firebasestorage.googleapis.com/v0/b/balizador.appspot.com/o/images%2Fcows%2Fboi_magro.png?alt=media&token=679fcfc0-91e2-4072-84ec-d7c441785612";
const vacaMagra =
    "https://firebasestorage.googleapis.com/v0/b/balizador.appspot.com/o/images%2Fcows%2Fvaca_magra.png?alt=media&token=9eabe0be-af49-49ea-8906-d1b77f59117a";

export default function Example() {
    const { currentUser, info, getInfo } = useAuth()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [progress, setProgress] = useState(0)
    const [validated, setValidated] = useState(false);
    const [step, setStep] = useState(0)
    const [data, setData] = useState({})
    const [categories, setCategories] = useState([])
    const [bonuses, setBonuses] = useState([])
    const [field, setField] = useState([]);
    const [dias, setDias] = useState([])
    const [states, setStates] = useState([])
    const [citiesD, setCitiesD] = useState([])
    const [citiesO, setCitiesO] = useState([])
    const [stateD, setStateD] = useState()
    const [stateO, setStateO] = useState()
    const [animalImage, setAnimalImage] = useState(vaca)
    const [date, setDate] = useState(new Date());
    const [ok, setOk] = useState(false)

    const [active, setActive] = useState();

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        setValidated(true);
    }

    useEffect(() => {
      //  getCategories()
        getBonuses()
        getDias()
        getState()
        getInfo()
    }, [])

    const getDias = () => {
        let arr = []
        for (var i = 1; i <= 90; i++) {
            arr.push(`${i} dia${i == 1 ? '' : 's'}`)
        }
        setDias(arr)
    }

    const getCategories = (type= "Abate") => {
        try {
            api
                .get(`/user-api/categories`, { params: { type: type == "Abate" ? 1 : 2 } })
                .then((response) => {
                    console.log(response.data)
                    setCategories(response.data.data)
                    setCategory(response.data.data[0])
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
        } catch (error) {
            console.error("ops! ocorreu um erro" + error);
        }
    }

    const getBonuses = () => {
        try {
            api
                .get(`/user-api/bonuses`)
                .then((response) => {
                    setBonuses(response.data.data)
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
        } catch (error) {
            console.error("ops! ocorreu um erro" + error);
        }
    }

    const getState = () => {
        api
            .get(`/user-api/states?s=120`)
            .then((response) => {
                console.log(response.data);
                setStates(response.data.data)
                // setStateD(response.data.data[0]?.id)
                // setStateO(response.data.data[0]?.id)

            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }

    const getCitiesOrigem = () => {
        if (!stateO) return null
        api
            .get(`/user-api/states/${stateO}/cities?s=10000`)
            .then((response) => {
                console.log(response.data);
                setCitiesO(response.data.data)
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }

    const getCitiesDestino = () => {
        if (!stateD) return null
        api
            .get(`/user-api/states/${stateD}/cities?s=10000`)
            .then((response) => {
                console.log(response.data);
                setCitiesD(response.data.data)
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }

    useEffect(() => {
        getCitiesDestino()
    }, [stateD])

    useEffect(() => {
        getCitiesOrigem()
    }, [stateO])

    function setCategory(value) {
        if (value == "Boi inteiro") {
            setAnimalImage(touro);
        } else if (value == "Boi castrado") {
            setAnimalImage(bezerro);
        } else if (value == "Vaca de até 15@" || value == "Vaca 15@ acima") {
            setAnimalImage(vaca);
        } else if (value == "Novilha de até 15@" || value == "Novilha 15@ acima") {
            setAnimalImage(novilha);
        } else if (value == "Bezerro macho de até 12 meses") {
            setAnimalImage(bezerroMacho);
        } else if (value == "Bezerra fêmea de até 12 meses") {
            setAnimalImage(bezerroFemea);
        } else if (value == "Garrote macho 12 a 24 meses") {
            setAnimalImage(garrote);
        } else if (value == "Novilha 12 a 24 meses") {
            setAnimalImage(novilha12);
        } else if (value == "Boi magro") {
            setAnimalImage(boiMagro);
        } else if (value == "Vaca magra") {
            setAnimalImage(vacaMagra);
        }else setAnimalImage(touro);
    }

    function setInputData(name, value) {
        console.log(name, value)
        let dados = data
        dados[name] = value;
        setData(dados)

        if (name == 'category') {
            setCategory(value)
        }
    }

    const [nego, setNego] = useState({})




    const Etone = () => {
        const [neg, setNeg] = useState({
            "anonymous": false,
            "class": "slaughter",
            "category": "Boi inteiro",
            "type": "sale",
            "quantity": "0",
            "value": 0,
            "image": "",
            "negotiationType": "Abate",
            "modality":""
        })

useEffect(() => {
    getCategoriess()
}, [])


        const [imgAnimaa, setimgAnimaa] = useState(touro)
        const [validated1, setValidated1] = useState(false)
        const [anmlclss, setAnmlclss] = useState();
        const [categoriess, setCategoriess] = useState([])
        var modalities = ["Balcão", "Termo"];

        setProgress(20)
        const handleChange = e => {
            const { name, value } = e.target;
            console.log(name, value)

            setNeg({
                ...neg,
                [name]: value
            });

            if (name === "category") setCategory(value)
            if (name === "negotiationType" ) setAnmlclss(value)

            console.log(neg)

            if (value === "Abate") setActive(true)
            if (value === "Reposição") setActive(false)
        };


        useEffect(() => {
            if(anmlclss)
                 getCategoriess(anmlclss)
        }, [anmlclss])
        

        const getCategoriess = (type= "Abate") => {
            try {
                api
                    .get(`/user-api/categories`, { params: { type: type == "Abate" ? 1 : 2 } })
                    .then((response) => {
                        console.log(response.data)
                        setCategoriess(response.data.data)
                        setCategory(response.data.data[0])
                    })
                    .catch((err) => {
                        console.error("ops! ocorreu um erro" + err);
                    });
            } catch (error) {
                console.error("ops! ocorreu um erro" + error);
            }
        }


        function setCategory(value) {
            if (value == "Boi inteiro") {
                setimgAnimaa(touro);
            } else if (value == "Boi castrado") {
                setimgAnimaa(bezerro);
            } else if (value == "Vaca de até 15@" || value == "Vaca 15@ acima") {
                setimgAnimaa(vaca);
            } else if (value == "Novilha de até 15@" || value == "Novilha 15@ acima") {
                setimgAnimaa(novilha);
            } else if (value == "Bezerro Macho de até 12 meses") {
                setimgAnimaa(bezerroMacho);
            } else if (value == "Bezerra Fêmea de até 12 meses") {
                setimgAnimaa(bezerroFemea);
            } else if (value == "Garrote Macho 12 a 24 meses") {
                setimgAnimaa(garrote);
            } else if (value == "Novilha 12 a 24 meses") {
                setimgAnimaa(novilha12);
            } else if (value == "Boi Magro") {
                setimgAnimaa(boiMagro);
            } else if (value == "Vaca Magra") {
                setimgAnimaa(vacaMagra);
            }else setimgAnimaa(touro);
        }

        const handleSubmit1 = (event) => {
            event.preventDefault();
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                setValidated1(false);
                event.stopPropagation();
            } else {
                setValidated1(true);
                neg.image = imgAnimaa
                neg.anonymous = (neg.anonymous ? (neg.anonymous?.toLowerCase() === 'true') : false)
                setNego(neg)
                console.log(neg)
                setStep(1)
            }
        }

        function mascararRS(v) {
            v = v.replace(/\D/g, "");
            v = v.replace(/^(\d{3})(\d{3})/g, "R$ $1,$2");
            v = v.replace(/(\d)(\d{4})$/, "$1-$2");
            console.log(v)
        }

        function formatarMoeda(elemento) {

            console.log('RS', elemento)

            var valor = elemento;

            valor = valor + '';
            valor = parseInt(valor.replace(/[\D]+/g, ''));
            valor = valor + '';
            valor = valor.replace(/([0-9]{2})$/g, ",$1");

            if (valor.length > 6) {
                valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
            }

            console.log('RdS', valor)

            setNeg({
                ...neg,
                'value': valor
            });
        }

        return (<div>
            <Form onSubmit={handleSubmit1} validated={validated1} >
                <Container>
                    <Form.Check
                        type="checkbox"
                        id={`default-checkbox`}
                        label={`Informar Anônimamente`}
                        value={!(neg.anonymous ? (neg.anonymous?.toLowerCase() === 'true') : false)}
                        name="anonymous"
                        onChange={handleChange}
                    />

                    <div className="neg-div-img">
                        <div>  <Image src={imgAnimaa} rounded /></div>
                        <div key={`inline-radio`} className="mb-3 neg-check">
                            <Form.Check
                                label="Venda"
                                name="type"
                                value="sale"
                                type="radio"
                                id={`inline-radio-1`}
                                onChange={handleChange}
                            
                                required
                            />
                            <Form.Check

                                label="Compra"
                                name="type"
                                type="radio"
                                value="purchase"
                                id={`inline-radio-2`}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>

                    <Row className="g-2">
                        <Col xs={6} class="form-check form-check-inline">
                            <Form.Check
                                label="Abate"
                                name="negotiationType"
                                value="Abate"
                                type="radio"
                                required
                              
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={6} class="form-check form-check-inline">
                            <Form.Check
                                label="Reposição"
                                name="negotiationType"
                                value="Reposição"
                                type="radio"
                                required
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>

                    <Row className="g-2 vld-row">
                        <Col xs={12} >
                            <FloatingLabel controlId="floatingSelectGrid" label="Categoria" >
                                <Form.Select aria-label="Categoria" size="sm" name="category" onChange={handleChange} required >
                                    {categoriess.map((_, key) => <option value={_}>{_}</option>)}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>

                    {anmlclss != "Reposição"  &&
                       <Row className="g-2 vld-row">
                       <Col xs={12} >
                           <FloatingLabel controlId="floatingSelectGrid" label="Categoria" >
                               <Form.Select aria-label="Modalidade de negociação" size="sm" name="modality" onChange={handleChange} required >
                                   {modalities.map((_, key) => <option value={_}>{_}</option>)}
                               </Form.Select>
                           </FloatingLabel>
                       </Col>
                   </Row>
                    
                    }

                    <Row className="g-2">
                        <Col xs={6}>
                            <FloatingLabel controlId="floatingSelectGrid" label="Quantidade" className="g-2 vld-row">
                                <Form.Control maxLength="5" size="sm" type="text" name="quantity" onChange={handleChange} required />
                            </FloatingLabel>
                        </Col>
                        <Col xs={6}>
                            <FloatingLabel controlId="floatingSelectGrid" label="R$/cabeça" className="g-2 vld-row" required>
                                <Form.Control size="sm" type="text" name="value" value={neg.value} required onChange={(e) => formatarMoeda(e.target.value)} />
                            </FloatingLabel>
                        </Col>
                    </Row>

                </Container>
                <Button type="submit" className="btn-dl-green" >Próximo</Button>
            </Form>
        </div>)
    }

    const Ettwo = () => {
        const [neg1, setNeg1] = useState({
            'isBonus': false,
            "bonus": "",
            "bonusValue": "",
            "tradingDate": "",
            "slaughterDate": "",
            "payment": "",
            "typepay": 'À vista'
        })
        const [mult, setMult] = useState()
        const [validated2, setvalidated2] = useState(false)
        const [options, setOptions] = useState([{ name: 'Option 1️⃣', id: 1 }, { name: 'Option 2️⃣', id: 2 }])

        setProgress(40)

        const handleChange = e => {
            const { name, value } = e.target;

            console.log(name, value)
            setNeg1({
                ...neg1,
                [name]: value
            });

            console.log(neg1)
        };

        function formatarMoeda(elemento) {

            console.log('RS', elemento)

            var valor = elemento;

            valor = valor + '';
            valor = parseInt(valor.replace(/[\D]+/g, ''));
            valor = valor + '';
            valor = valor.replace(/([0-9]{2})$/g, ",$1");

            if (valor.length > 6) {
                valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
            }

            console.log('RdS', valor)


            setNeg1({
                ...neg1,
                'bonusValue': `R$ ${valor}`
            });
        }

        const handleSubmit1 = (event) => {
            console.log('neggggggggg', neg1)
            event.preventDefault();
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                setvalidated2(false);
                event.stopPropagation();
            } else {
                setvalidated2(true);

                neg1.bonus = mult
                neg1.payment = (neg1.typepay === "Prazo" ? neg1.payment : "À vista")
                console.log('neggggggggg', neg1)
                neg1.tradingDate = moment(neg1.tradingDate).format('DD/MM/YYYY');
                neg1.slaughterDate = moment(neg1.slaughterDate).format('DD/MM/YYYY');
                console.log(neg1)

                // let dataAtual = new Date(neg1.tradingDate); //29/01/2020

                // neg1.tradingDate = (adicionaZero(dataAtual.getDate().toString()) + "/" + (adicionaZero(dataAtual.getMonth()+1).toString()) + "/" + dataAtual.getFullYear());
                // console.log('data:',neg1.tradingDate, (adicionaZero((dataAtual.getDate()+1).toString()) + "/" + (adicionaZero(dataAtual.getMonth()+1).toString()) + "/" + dataAtual.getFullYear()))

                // let dataAtual2 = new Date(neg1.slaughterDate); //29/01/2020

                // console.log('data:',dataAtual2, (adicionaZero((dataAtual2.getDate()+1).toString()) + "/" + (adicionaZero(dataAtual2.getMonth()+1).toString()) + "/" + dataAtual2.getFullYear()))
                // neg1.slaughterDate= (adicionaZero(dataAtual2.getDate().toString()) + "/" + (adicionaZero(dataAtual2.getMonth()+1).toString()) + "/" + dataAtual2.getFullYear());

                // neg.image= imgAnimal
                // neg.anonymous = (neg.anonymous ? (neg.anonymous?.toLowerCase() === 'true') : false)
                setNego({ ...nego, ...neg1 })
                // console.log(neg)
                setStep(2)
            }


        }

        function onSelect(selectedList, selectedItem) {
            console.log(selectedList, selectedItem)
            setMult(selectedList)
        }

        function onRemove(selectedList, removedItem) {
            console.log(selectedList, removedItem)
            setMult(selectedList)
        }

        function adicionaZero(numero) {
            if (numero <= 9)
                return "0" + numero;
            else
                return numero;
        }

        // let dataAtual = new Date("2020-1-29"); //29/01/2020
        // let dataAtualFormatada = (adicionaZero(dataAtual.getDate().toString()) + "/" + (adicionaZero(dataAtual.getMonth()+1).toString()) + "/" + dataAtual.getFullYear());
        // console.log(dataAtualFormatada);
        // // saída: 29/01/2020
        // let dataAtual2 = new Date("2020-10-2"); //02/10/2020
        // let dataAtualFormatada2 = (adicionaZero(dataAtual2.getDate().toString()) + "/" + (adicionaZero(dataAtual2.getMonth()+1).toString()) + "/" + dataAtual2.getFullYear());
        // console.log(dataAtualFormatada2);

        return (<div>
            <Form validated={validated2} onSubmit={handleSubmit1}>
                <Container>
                    {active &&
                        <Form.Check
                            type="checkbox"
                            id={`default-checkbox`}
                            label={`Possui bonificações`}
                            value={!(neg1.isBonus ? (neg1.isBonus?.toLowerCase() === 'true') : false)}
                            name="isBonus"
                            onChange={handleChange}
                        /> 
                    }

                    {(neg1.isBonus ? (neg1.isBonus?.toLowerCase() === 'true') : false) && <Row className="g-2 vld-row">
                        <Col xs={12} >
                            <Multiselect
                                isObject={false}
                                options={bonuses} // Options to display in the dropdown
                                selectedValues={(a) => setMult(a)} // Preselected value to persist in dropdown
                                onSelect={onSelect} // Function will trigger on select event
                                onRemove={onRemove} // Function will trigger on remove event
                                displayValue="" // Property name to display in the dropdown options
                                placeholder="Selecione"
                            />
                        </Col>

                        {/* <FloatingLabel controlId="floatingSelectGrid" label="Bonificações" >
                            <Form.Control as="select" multiple  name="bonusValue" onChange={e => setMult([].slice.call(e.target.selectedOptions).map(item => item.value))}>
                            {bonuses.map((_, key) => <option value={_}>{_}</option>)}
                           
                          </Form.Control>
                          <div> <p style={{fontSize: 10}}>Pressione o Ctrl para selecionar múltiplas Bonificações</p>
                            </div>
                            </FloatingLabel>
                        </Col> */}
                        <Col xs={12}>
                            <FloatingLabel controlId="floatingSelectGrid" label="Valor Bonificações" className="g-2 vld-row">
                                <Form.Control size="sm" type="text" required name="bonusValue" value={neg1.bonusValue} onChange={(e) => formatarMoeda(e.target.value)} />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    }
                    <Row className="g-2">
                        <Col >
                            <FloatingLabel controlId="floatingSelectGrid" label="Data da Negociação" className="g-2 vld-row">
                                <Form.Control
                                    type="date"
                                    name="tradingDate"
                                    placeholder="Data da Negociação"
                                    data-date-format="mm/dd/yyyy"
                                    onChange={handleChange}
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    {active &&
                        <Row className="g-2">
                            <Col >
                                <FloatingLabel controlId="floatingSelectGrid" label="Data do Abate" className="g-2 vld-row">
                                    <Form.Control
                                        type="date"
                                        name="slaughterDate"
                                        placeholder="Data do Abate"
                                        data-date-format="mm/dd/yyyy"
                                        onChange={handleChange} 
                                    />
                                </FloatingLabel>
                            </Col>
                        </Row>
                    }

                    <Row className="g-2 vld-row">
                        <Col xs={8}>
                            <Form.Check
                                inline
                                label="À vista"
                                value="À vista"
                                name="typepay"
                                type="radio"
                                id={`inline-radio-1`}
                                onChange={handleChange}
                            />
                            <Form.Check
                                inline
                                label="Prazo"
                                value="Prazo"
                                name="typepay"
                                type="radio"
                                id={`inline-radio-2`}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={5} >
                            {
                                neg1.typepay === "Prazo" &&
                                <FloatingLabel controlId="floatingSelectGrid" label="Prazo" >
                                    <Form.Select aria-label="Pazo" name="payment" size="sm" onChange={handleChange} required >
                                        {dias.map((_, key) => <option value={_}>{_}</option>)}
                                    </Form.Select>
                                </FloatingLabel>
                            }
                        </Col>
                    </Row>
                </Container>
                <Button type="submit" className="btn-dl-green" >Próximo</Button>
            </Form>
        </div>)
    }

    const Ettree = () => {

        console.log(nego)

        const [neg3, setNeg3] = useState({
            "originUF": "",
            "originCity": "",
            "destinationUF": "",
            "destinationCity": "",
            "property": "",
            "slaughterHouse": "",
            "squareDestination": "",
            "squareOrigin":""

        })

        setProgress(60)

        const [validated3, setvalidated3] = useState(false)

        const [state3, setState3] = useState([])
        const [citiesO, setCitiesO] = useState([])
        const [citiesD, setCitiesD] = useState([])

        const handleChange3 = e => {
            const { name, value } = e.target;
            console.log(name, value)
            setNeg3({
                ...neg3,
                [name]: value
            });

            if (name === "originUF") getCitiesOrigem(value)
            if (name === "destinationUF") getCitiesDestino(value)


            if (name === "originCity") square(citiesO, value, "squareOrigin" )
            if (name === "destinationCity") square(citiesD, value, "squareDestination" )


            


            console.log(neg3)
        };


        const square = (array, name, type) => {

            const found = array.find(element => element.name ==name );
            if(found){
                console.log(found)
              

                setNeg3({
                    ...neg3,
                    [type]: found.square!= "" ?found.square : "" 
                });
            }


        }


        const handleSubmit3 = (event) => {
            event.preventDefault();
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                setvalidated3(false);
                event.stopPropagation();
            } else {
                setvalidated3(true);
                // neg1.bonus = mult
                // neg1.payment = (neg1.typepay === "Prazo"? neg1.payment: "À vista")
                console.log(neg3)

                var c1 = state3.filter((item) => item.id == neg3.originUF);
                var c3 = state3.filter((item) => item.id == neg3.destinationUF);

                console.log(c1, c3)
                neg3.originUF = `${c1[0].name} - ${c1[0].abbr}`
                neg3.destinationUF = `${c3[0].name} - ${c3[0].abbr}`

                // // neg.image= imgAnimal
                // // neg.anonymous = (neg.anonymous ? (neg.anonymous?.toLowerCase() === 'true') : false)
                setNego({ ...nego, ...neg3 })
                // // console.log(neg)
                setStep(3)
            }
        }

        useEffect(() => {
            getState3()
        }, [])

        const getState3 = () => {
            api
                .get(`/user-api/states?s=120`)
                .then((response) => {
                    console.log(response.data);
                    setState3(response.data.data)
                    getCitiesOrigem(response.data.data[0]?.id)
                    getCitiesDestino(response.data.data[0]?.id)
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
        }

        const getCitiesOrigem = (id) => {
            if (!id) return null
            api
                .get(`/user-api/states/${id}/cities?s=10000`)
                .then((response) => {
                    console.log(response.data);
                    setCitiesO(response.data.data)
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
        }

        const getCitiesDestino = (id) => {
            if (!id) return null
            api
                .get(`/user-api/states/${id}/cities?s=10000`)
                .then((response) => {
                    console.log(response.data);
                    setCitiesD(response.data.data)
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
        }

        return (<div>
            <Form validated={validated3} onSubmit={handleSubmit3}>
                <Container>
                    <Row className="g-2 vld-row">
                        <Col xs={12} >
                            <FloatingLabel controlId="floatingSelectGrid1" label="UF de Origem" id="originUF" >
                                <Form.Select aria-label="Estado /UF" size="sm" name="originUF" onChange={handleChange3} required id="originUF" >
                                    <option value="" >Selecione o UF de Origem</option>
                                    {state3.map((_, key) => <option value={_.id} >{_.name} - {_.abbr}</option>)}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="g-2 vld-row">
                        <Col xs={12} >
                            <FloatingLabel controlId="floatingSelectGrid2" label="Cidade de Origem" >
                                <Form.Select aria-label="Estado /UF" size="sm" name="originCity" onChange={handleChange3} id="cityOrigem">
                                    <option value="" >Selecione a Cidade de Origem</option>

                                    {citiesO.map((_, key) => <option value={_.name}>{_.name}</option>)}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="g-2 vld-row">
                        <Col xs={12} >
                            <FloatingLabel controlId="floatingSelectGrid3" label="UF de Destino" id="destinationUF" >
                                <Form.Select aria-label="Estado /UF" size="sm" name="destinationUF" onChange={handleChange3} required id="destinationUF">
                                    <option value="" >Selecione o UF de Destino</option>
                                    {states.map((_, key) => <option value={_.id}>{_.name} - {_.abbr}</option>)}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="g-2 vld-row">
                        <Col xs={12} >
                            <FloatingLabel controlId="floatingSelectGrid4" label="Cidade de Destino" >
                                <Form.Select aria-label="Estado /UF" size="sm" name="destinationCity" onChange={handleChange3} required id="cityDestino">
                                    <option value="" >Selecione a Cidade de Destino</option>
                                    {citiesD.map((_, key) => <option value={_.name}>{_.name}</option>)}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="g-2 vld-row">
                        <Col xs={12} >
                            <FloatingLabel controlId="floatingSelectGrid5" label="Propriedade" >
                                <Form.Select aria-label="Estado /UF" size="sm" name="property" onChange={handleChange3} required >
                                    <option value="" >Selecione uma Propriedade</option>
                                    {info.properties.map((_, key) => <option value={_.name}>{_.name}</option>)}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    {active &&
                        <Row className="g-2">
                            <Col xs={12}>
                                <FloatingLabel controlId="floatingSelectGrid" label="Indústrial/Abatedouro" className="g-2 vld-row">
                                    <Form.Control size="sm" type="text"  name="slaughterHouse" onChange={handleChange3} />
                                </FloatingLabel>
                            </Col>
                        </Row>
                    }
                </Container>
                <Button type="submit" className="btn-dl-green">Próximo</Button>
            </Form>
        </div>)
    }

    const Etfour = () => {
        setProgress(80)
        const [neg4, setNeg4] = useState({
            "weightMode": "",
            "weigthPercent": "",
            "funrural": "",
            "freight": "",
            "nutrition": "",
            "breed": "",
            "weigthType": "true",
            "shipping": "true"
        })

        const [validated4, setvalidated4] = useState(false)
        const [WeigthPercentages, setWeigthPercentages] = useState([])
        const [feeds, setFeeds] = useState([])
        const [Breeds, setBreeds] = useState([])
        const [funrural, setFunrural] = useState([])

        useEffect(() => {
            getWeigthPercentages()
            getFeeds()
            getBreeds()
            getFunRural()
        }, [])

        const handleChange4 = e => {
            const { name, value } = e.target;
            console.log(name, value)
            setNeg4({
                ...neg4,
                [name]: value
            });
            console.log(neg4)
        };

        const handleSubmit4 = (event) => {
            event.preventDefault();
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                setvalidated4(false);
                event.stopPropagation();
            } else {

                setvalidated4(true);

                neg4.freight = (neg4.shipping === "true" ? "FOB" : "CIF")
                neg4.weightMode = (neg4.weigthType === "true" ? "Peso Vivo" : "Peso Morto")

                setNego({ ...nego, ...neg4 })
                // // console.log(neg)
                setOk(true)
                setStep(4)
                // neg.image= imgAnimal
                // neg.anonymous = (neg.anonymous ? (neg.anonymous?.toLowerCase() === 'true') : false)
                // setNego(neg)
                // console.log(neg)
                // setStep(1)
            }
        }

        const getWeigthPercentages = () => {
            let arr = []
            for (var i = 47; i <= 57; i++) {
                arr.push(`${i}%`)
            }
            setWeigthPercentages(arr)
        }

        const getFeeds = () => {
            api
                .get(`/user-api/feeds`)
                .then((response) => {
                    console.log(response.data);
                    setFeeds(response.data.data)
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
        }
        const getBreeds = () => {
            api
                .get(`/user-api/breeds`)
                .then((response) => {
                    console.log(response.data);
                    setBreeds(response.data.data)
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
        }
        const getFunRural = () => {
            api
                .get(`/user-api/funrural`)
                .then((response) => {
                    console.log(response.data);
                    setFunrural(response.data.data)
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
        }

        return (<div>
            <Form validated={validated4} onSubmit={handleSubmit4}>
                <Container>
                    {active &&
                        <Row className="g-2 vld-row">
                            <Col xs={8}>
                                <Form.Check
                                    inline
                                    label="Peso Vivo"
                                    type="radio"
                                    value="true"
                                    name="weigthType"
                                    onChange={handleChange4}
                                    id={`inline-radio-1`}
                                    required
                                
                                />
                                <Form.Check
                                    inline
                                    label="Peso Morto"
                                    type="radio"
                                    value="false"
                                    name="weigthType"
                                    onChange={handleChange4}
                                    id={`inline-radio-2`}
                                    required
                                />
                            </Col>
                        </Row> 
                    }

                    {active && neg4.weigthType === "true" && 
                        <Row className="g-2 vld-row">
                            <Col xs={12} >
                                <FloatingLabel controlId="floatingSelectGrid" label="Porcentagem de peso vivo" >
                                <Form.Select aria-label="Estado /UF" size="sm" name="weigthPercent" onChange={handleChange4} required id="stateDestiny">
                                <option  value="" >Selecione</option>
                                {WeigthPercentages.map((_, key) => <option value={_}>{_}</option>)}
        
                            </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                    }

                    <Row className="g-2 vld-row">
                        <Col xs={12} >
                            <FloatingLabel controlId="floatingSelectGrid" label="FunRural" >
                                <Form.Select aria-label="Estado /UF" size="sm" name="funRural" onChange={handleChange4} required id="stateDestiny">
                                    <option value="" >Selecione</option>

                                    {funrural.map((_, key) => <option value={_}>{_}</option>)}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>

                    <Row className="g-2 vld-row">
                        <Col xs={8}>

                            <Form.Check
                                inline
                                label="FOB"
                                value="true"
                                type="radio"
                                name="shipping"
                                onChange={handleChange4}
                                id={`inline-radio-3`}
                                required
                            />
                            <Form.Check
                                inline
                                label="CIF"
                                type="radio"
                                value="false"
                                name="shipping"
                                onChange={handleChange4}
                                id={`inline-radio-4`}
                                required
                            />
                        </Col>
                    </Row>
                    {active &&
                        <Row className="g-2 vld-row">
                            <Col xs={12} >
                                <FloatingLabel controlId="floatingSelectGrid" label="Nutrição" >
                                <Form.Select aria-label="Estado /UF" size="sm" name="nutrition" onChange={handleChange4} required id="stateDestiny">
                                <option  value="" >Selecione</option>
                                
                                {feeds.map((_, key) => <option value={_}>{_}</option>)}
        
                            </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row> 
                    }
                    <Row className="g-2 vld-row">
                        <Col xs={12} >
                            <FloatingLabel controlId="floatingSelectGrid" label="Raça Animal" >

                                <Form.Select aria-label="Estado /UF" size="sm" name="breed" onChange={handleChange4} required id="stateDestiny">
                                    <option value="" >Selecione</option>

                                    {Breeds.map((_, key) => <option value={_}>{_}</option>)}

                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>

                </Container>
                <Button type="submit" className="btn-dl-green">Próximo</Button>
            </Form>
        </div>)
    }

    const Etend = ({ props }) => {

        setProgress(100)

        useEffect(() => {
            var data = {
                "isPublic": false,
                "type": "negotiations",
                "content": {
                    "status": "Pendente",
                    "type": nego.type == "sale" ? "Venda" : "Compra",
                    "negotiationType":nego.negotiationType,
              

                    "image": nego.image ?? "",
                    "modality": nego.modality ?? "",
                    "category": nego.category ?? "",
                    "value": nego.value.toString() || "0.0",
                    "quantity": nego.quantity ?? "0",
                    "bonus": nego.bonus ? nego.bonus.join(';') : '',
                    "bonusValue": nego.bonusValue.toString() || "0.0",
                    "tradingDate": nego.tradingDate ?? "",
                    "slaughterDate": nego.slaughterDate ?? "",
                    "payment": nego.payment ?? "",
                    "originUF": nego.originUF ?? "",
                    "originCity": nego.originCity ?? "",
                    "property": nego.property ?? "",
                    "destinationUF": nego.destinationUF ?? "",
                    "destinationCity": nego.destinationCity ?? "",
                    "slaughterHouse": nego.slaughterHouse ?? "",
                    "weightMode": nego.weightMode ?? "",
                    "weightPercent": nego.weigthPercent ?? "",
                    "funrural": nego.funRural ?? "",
                    "freight": nego.freight ?? "",
                    "nutrition": nego.nutrition ?? "",
                    "breed": nego.breed ?? ""
                },
                "anonymous": nego.anonymous ?? false
            };

            if (ok == true) setAdd(data)

        }, [props])

        const setAdd = (data) => {
            console.log(data)
            setOk(false)
            api
                .post("/publication-api/publications", data)
                .then((response) => {
                    console.log(response)
                })
                .catch((err) => {
                    console.log("ops! ocorreu um erro" + err);
                });
        }
        //publication-api/publications/

        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: imgLoader,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };

        return (<div>
            <div>
                <h4>Negociação informada com sucesso!</h4>
            </div>
            <Lottie
                options={defaultOptions}
                height={400}
                width={400}
            />
            <Button type="button" onClick={() => {
                handleClose()
                setStep(0)
                setProgress(0)
                setOk(false)
            }}  >Pronto!</Button>
        </div>);
    }
    return (
        <>

            <Button
                className="btn-circle"
                onClick={handleShow}
                variant="success"
                style={{ color: "#000", backgroundColor: "#ccc", margin: 10 }}
            >+</Button>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title style={{ fontWeight: 700, textAlign: 'center' }}>Adicionar Negociação</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ProgressBar now={progress} style={{ marginBottom: 20 }} label={`${progress}%`} variant="success" />

                    {step == 0 && <Etone />}
                    {step == 1 && <Ettwo />}
                    {step == 2 && <Ettree />}
                    {step == 3 && <Etfour />}
                    {step == 4 && <Etend props={true} />}

                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}