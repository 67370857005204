import React, { useEffect, useState } from 'react'
import ReactLoading from 'react-loading';
import styles from './Chart.css'
const anychart = window.anychart;




anychart.format.outputLocale("pt-br");
anychart.format.outputDateTimeFormat("dd-MM-yyyy HH:mm:ss");
anychart.exports.filename('DATAGRO');


const CREDITS = {
    "text": "DATAGRO",
    "url": "https://www.datagro.com",
    "alt": "DATAGRO - Consultoria agrícola independente, que produz análises e dados primários sobre as principais commodities agrícolas",
    "imgAlt": "Datagro - Consultoria Agrícola",
    "logoSrc": "https://portal.datagro.com/favicon.ico",
    "enabled": true,
}






const PLOT = {
    legend: false,
    yAxis: {
        orientation: 'right',
        labels: {
            format: "{%value}"
        }
    },

}
    ;



function Line({ data, error, id="2" }) {
console.log(id)

    const [chart, setChart] = useState(null)

    useEffect(() => {
console.log(data)

        let arr = []
       
        for (var i = 0; i < data.length; i++) {
            arr.push([data[i].dia, data[i].ult])
        }
        console.log(arr)

        var chrt = anychart.stock();
        var plot = chrt.plot(0);
        plot.yGrid(true)
            .xGrid(true)
            .yMinorGrid(false)
            .xMinorGrid(false);
            chrt.animation(true);
            chrt.scroller().enabled(false);
        // chrt.scroller().line(arr);
        // chrt.scroller().fill('green 0.1');
        // chrt.scroller().selectedFill('green 0.5');
        // chrt.scroller().palette(['#006400'])



        var series = plot.line(arr);
        plot.palette(['#98bf0e']);
        if (data[0]) {
            series.name(data[0].cod);
        }
        // chrt.contextMenu().itemsFormatter(function (items) {
        //     var index = items['full-screen-enter'].index;
        //     delete items["full-screen-separator"];
        //     delete items["about"];
        //     items['separador-datagro'] = {
        //         'index': index + 0.02
        //     };
        //     // add item with custom url
        //     items['datagro'] = {
        //         'text': 'About Datagro',
        //         'href': 'https://datagro.com/',
        //         'index': index + 0.10
        //     };

        //     return items;
        // });
        chrt.draw();
  
        chrt.padding().right(50);
    
        chrt.container(`conteniner-${id}`);
        chrt.credits(CREDITS);
        setChart(chrt)

    }, [])


    return (<div>
        <div id={`conteniner-${id}`}  className="contenerChart"  >
        </div>
        </div>)
}


function Candle({ data, error, id='1' }) {
    const [chart, setChart] = useState(null)



    useEffect(() => {
        let arr = []
       
     
        for (var i = 0; i < data.length; i++) {
            let a = i==0 ? 0: i-1;

            let maxi = data[i].maxi? data[i].maxi : data[i].ult
            let mini= data[i].mini? data[i].mini: data[i].ult;
            let  abe= data[i].abe?data[i].abe: data[i].ult;
            arr.push([data[i].dia, abe, maxi, mini, data[i].ult])
        }

        var chrt = anychart.stock();
        var plot = chrt.plot(0);

        plot.yGrid(true)
            .xGrid(true)
            .yMinorGrid(false)
            .xMinorGrid(false);

        chrt.scroller().line(arr);
        chrt.scroller().fill('green 0.1');
        chrt.scroller().selectedFill('green 0.5');
        chrt.scroller().palette(['#006400'])


        var table = anychart.data.table();
        table.addData(arr)


        var mapping = table.mapAs();
        mapping.addField('open', 1);
        mapping.addField('high', 2);
        mapping.addField('low', 3);
        mapping.addField('close', 4);

        var extraYAxis = plot.yAxis(1);
        extraYAxis.orientation("right");
        
        var series = plot.ohlc(mapping);


        if (data[0])
            series.name(data[0].cod);

        // chrt.contextMenu().itemsFormatter(function (items) {
        //     var index = items['full-screen-enter'].index;
        //     delete items["full-screen-separator"];
        //     delete items["about"];
        //     items['separador-datagro'] = {
        //         'index': index + 0.02
        //     };
        //     // add item with custom url
        //     items['datagro'] = {
        //         'text': 'About Datagro',
        //         'href': 'https://datagro.com/',
        //         'index': index + 0.10
        //     };

        //     return items;
        // });

        // series.risingFill('green', 1)
        //     .fallingFill('red', 1)
        //     .risingStroke('green', 1)
        //     .fallingStroke('red', 1);

        chrt.credits(CREDITS);
       // chrt.contextMenu(false);
        chrt.draw();
        chrt.padding().right(50);
        chrt.container(`conteniner-${id}`);
        setChart(chrt)


     // create data
    

    //   let arr = []
       
     
    //       for (var i = 0; i < data.length; i++) {

    //         let a = i==0 ? 0: i-1;
    //           let maxi = data[i].maxi? data[i].maxi : data[i].ult
    //           let mini= data[i].mini? data[i].mini: data[i].ult;
    //           let abe= data[i].abe? data[i].abe: data[].ult ;
    //           arr.push([data[i].dia, abe, maxi, mini, data[i].ult])
    //       }
  
    //       console.log(arr)

       
    //   // create a chart
    //   var chart = anychart.ohlc();
  
    //   // set the interactivity mode
    //   chart.interactivity("by-x");
  
    //   // create an OHLC series and set the data
    //   var series = chart.ohlc(arr);
  
    //   // set the chart title
    //   chart.title("OHLC Chart: Basic Sample");
  
    //   // set the titles of the axes
    //   chart.xAxis().title("Date");
    //   chart.yAxis().title("Price, $");
  
    //   // set the container id
    //   chart.container(`conteniner-${id}`);
  
    //   // initiate drawing the chart
    //   chart.draw();

      
    //        setChart(chart)

    }, [])


    return  (<div>
    <div id={`conteniner-${id}`}   className="contenerChart"  >
</div>
</div>)

    }







export default function ChartAnyChart({ query, type, id }) {

    const [chart, setChart] = useState(2);
    const [quote, setQuote] = useState(false);
    

     let selectType = type == 1 ? Line : Candle


        useEffect(() => {
            setChart(type)
        }, [type]);




    

    function params(data) {
        return Object.keys(data).map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
      }

    useEffect(() => {
        setQuote(false)
        async function getNews() {
            let pa = params(query);
            console.log('pa', pa)
            let response = await fetch(`https://precos.api.datagro.com/dados/?idioma=pt-br&${pa}&cliente=a81574eb-06c1-11ed-bcba-02ebefa3b928`);
            console.log(response.body)
            let resJson = await response.json();
            console.log('Chart:',resJson);
            setQuote(resJson)
        }
        getNews()
    }, [query]);

    if(!quote) return (
        <div style={{display: 'flex', flexDirection:'row', justifyContent:'center', height:400, alignContent:'center' }}>
            <ReactLoading type={'spokes'} color={'#000'} height={50} width={50}  />
        </div>
    )

    if(type==1) return <Line data={quote}  id={id}/>
    else return  <Candle data={quote}  id={id}/>

    
}

