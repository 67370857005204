import React, { useState, useEffect, useRef } from 'react'
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

import api from '../../Services/Api'
import { useAuth } from "../../contexts/AuthContext"

import { Link, useHistory, useParams } from "react-router-dom"
import { Form, Button, InputGroup, Col, FloatingLabel, Row, Container, Alert, Image } from 'react-bootstrap'



import './Plan.css'
import imaPlan from './../../Assets/icons/register_plans.png'
import imaTag from './../../Assets/images/tag-alt.svg'


import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Confirm, CustomDialog, useDialog } from 'react-st-modal';
import { decode as base64_decode, encode as base64_encode } from 'base-64';

import Loading from '../../Components/Widgets/Loading'
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";



import {isMobile} from 'react-device-detect';

const getItems = () =>
  Array(20)
    .fill(0)
    .map((_, ind) => ({ id: `element-${ind}` }));


function App({ next }) {
  const [items, setItems] = React.useState(getItems);
  const [selected, setSelected] = React.useState([]);
  const [position, setPosition] = React.useState(0);
  const [loading, setLoading] = useState(true)
  const { push } = useHistory()


  const { currentUser, setPlan, getInfo, info } = useAuth()
  const [plans, setPlans] = useState([])
  const [plansUser, setPlansUser] = useState({})

  const [show, setShow] = useState(false);
  const target = useRef(null);




  






useEffect(() => {
  if(!info.id) console.log('user', undefined)
  else{
    console.log('userd',currentUser)
    console.log('userd',info)
    GetPlan()
  }

}, [info])



const GetPlan = () => {
  api
    .get(`/user-api/plans/actives`)
    .then((response) => {
      console.log(response.data);
      setPlans(response.data.data)   
      fiterPlan(response.data.data)
    })
    .catch((err) => {
      console.error("ops! ocorreu um erro" + err);
      setLoading(false)
    });
}



  const getPlanUser = () =>{
    try {
      api
      .get(`/user-api/plans/${info.subscriptions[0].planId}`)
      .then((response) => {
         return response.data.data
        
         
      })
      .catch((err) => {
          console.log("ops! ocorreu um erro" + err);
          
        
      });
    } catch (error) {
      console.log(error)
    }
         
  }





async function fiterPlan(tPlans) {

  console.log('plansss', tPlans, info)


  function b_PlanUser (i) {

    if ( i.id === info.activeSubscriptionId) 
    return i;
   }
   let planUser = info.subscriptions.filter(b_PlanUser)




 function buscarNumerosPares (i) {
   console.log(i)
  if ( i.id != planUser[0].planId ) 
  return i;
}
let ddd = tPlans.filter(buscarNumerosPares)




console.log(ddd, planUser)
  setPlans(ddd)
  setPlansUser(planUser[0])

  setLoading(false)







}











  async function AddPlan(plan) {


    console.log(plan)

    if(plan.type == 'free'){
      //let MsgColaborador = plansUser.type  == 'collaborator' ?  'Ao sair do plano Colaborador, caso você esteja dentro do período gratuito, você abre mão dos dias gratuitos restantes. Tem certeza que deseja prosseguir?': '';
      const isConfirm = await Confirm(
       // MsgColaborador,
        'Tem certeza que deseja atualizar para o plano Grátis?',
        );
      if (isConfirm) {
        putFreeUserSubscription(plan)
      }
    }



    else if (plan.type == 'collaborator') {
      const isConfirm = await Confirm(
        // 'You cannot undo this action',
        'Após os 3 meses gratuitos, será cobrado um valor de R\$ 9,90 por mês, caso permaneça no Plano Colaborador. Havendo a possibilidade de alteração do plano para Grátis ou Pago (B ou A).'
      );
      if (isConfirm) {
        putFreeUserSubscription(plan)
      }
    }
    else putFreeUserSubscription(plan)
  }


  async function userUpdate(plan) {

    if(plan.type == 'free'){ 
       await getInfo()
      if(isMobile) {
      
        const isConfirm = await Confirm(
          // 'You cannot undo this action',
          'Quer voltar para o Aplicativo?'
        );
        if (isConfirm) {
          window.open('balizador://');
          push('/app/plan')
        }else{
         
          push('/app/plan')
        }
      }else{
        push('/app/plan')
      }

       
    }
    else{
      push('/app/methods')
    }
  
  
  }


  async function cancelUserSubscription(plan) {
    console.log(`/user-api/subscriptions/${currentUser.user.id}/cancel`)
    api
      .post(`/user-api/subscriptions/${currentUser.user.id}/cancel`)
      .then((response) => {
        console.log(response)
        let subscript = response.data.data
        // subscript['plan'] = plan
        // setPlan(subscript)
        // localStorage.setItem('PLAN', JSON.stringify(subscript));
        // history.push("/term")
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }


      async function putFreeUserSubscription(plan) {
        let userSubscriptions = info.activeSubscriptionId
        api
          .put(`/user-api/users/${currentUser.user.id}/subscriptions/${userSubscriptions}`, { planId: plan.id })
          .then((response) => {
            let subscript = response.data.data
            subscript['plan'] = plan
            setPlan(subscript)
            userUpdate(plan)
          })
          .catch((err) => {
            console.error("ops! ocorreu um erro" + err);
          });
      }
    



  async function addUserSubscription(plan) {
    api
      .post(`/user-api/users/${currentUser.user.id}/subscriptions`, { planId: plan.id })
      .then((response) => {
        let subscript = response.data.data
        subscript['plan'] = plan
        setPlan(subscript)
        localStorage.setItem('PLAN', JSON.stringify(subscript));
        push("/term")
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }


  const isItemSelected = (id) => !!selected.find((el) => el === id);

  const handleClick = (id) => ({ getItemById, scrollToItem }) => {
    const itemSelected = isItemSelected(id)

    setSelected((currentSelected) =>
      itemSelected
        ? currentSelected.filter((el) => el !== id)
        : currentSelected.concat(id)
    );
  }




  // const renderTooltip = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     Simple tooltip
  //   </Tooltip>
  // );

  // const Cupom = (props) => <OverlayTrigger
  //     placement="right"
  //     delay={{ show: 250, hide: 400 }}
  //     overlay={renderTooltip}
  //   >
  //     <Button variant="success"
  //         style={{ color: "white", background: "#bbb", width: 100, height:40, padding: 5}} >Cupom</Button>
  //   </OverlayTrigger>


  function Cupom({ plan }) {
    const [value, setValue] = useState("");
    const [res, setRes] = useState();
    const [errorCupom, setErrorCupom] = useState();
    const dialog = useDialog();

    async function Send() {
      var f = `mainPlainId:${plan.id}|key:${value}`;
      let encoded = base64_encode(f);
      console.log(encoded)
      console.log(base64_decode(encoded))
      api
        .get(`/user-api/tickets?f=${encoded}`)
        .then((response) => {
          console.log(response.data.data)
          setRes(response.data.data[0])

        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data)
            setErrorCupom(true)
          }
        });

      // {
      //   // Сlose the dialog and return the value
      //   dialog.close(value);
      // }
    }



    async function addUserSubscription() {
      let userSubscriptions = info.activeSubscriptionId
      api
        .put(`/user-api/users/${currentUser.user.id}/subscriptions/${userSubscriptions}`, { planId: res.ticketPlanId })
        .then((response) => {
          let subscript = response.data.data
          subscript['plan'] = plan
          subscript['ticketplan'] = res
          setPlan(subscript)
          localStorage.setItem('PLAN', JSON.stringify(subscript));
          dialog.close(value)
          push("/app/methods")
        })
        .catch((err) => {
          console.error("ops! ocorreu um erro" + err);
        });
    }


    if (res) {
      return (
        <div className="cupom">
          <div className="cupom-header">
            <p style={{ fontSize: 25, fontWeight: 600 }}>Cupom inserido com sucesso!</p>
            <Image src={imaTag} />
          </div>
          <div className="cupom-res">
            <div className="cupom-res-ass">Assinatura: {plan.name} </div>
            <div className="cupom-res-price">Preço Normal: R$ {plan.price} </div>
            <div className="cupom-res-cupom">Você pagará: R$ {res.price} </div>
          </div>
          <Button variant="success"
            onClick={() => addUserSubscription()}
            disabled={value.length > 4 ? '' : 'eee'}
          >
            {plan.type != "cupom" ? 'Ir para pagamento' : "Próximo"}
          </Button>
        </div>
      );
    }

    return (
      <div className="cupom">
        <div className="cupom-header">
          <p style={{ fontSize: 25, fontWeight: 600 }}>Possuí um cupom?</p>
          <Image src={imaTag} />
        </div>
        <p>Digite o código abaixo e garanta seu desconto!</p>
        {errorCupom && <Alert variant='danger' onClose={() => setErrorCupom(false)} dismissible>
          O cupom utilizado é inválido ou expirado
        </Alert>}
        <FloatingLabel controlId="floatingSelectGrid" label="Digite o cupom" className="g-2 vld-row">
          <Form.Control size="sm" type="text" required isValid={false} value={value} onChange={e => setValue(e.target.value)} placeholder="placeholder" />
          <Form.Control.Feedback type="invalid">
            Digite o cupom
          </Form.Control.Feedback>
        </FloatingLabel>
        <Button variant="success"
          onClick={() => Send()}
          disabled={value.length > 4 ? '' : 'eee'}
        >
          Enviar
        </Button>
      </div>
    );
  }



  if (loading) return <Loading />



  return (
    <div className="signup-content">
      <div className="signup-title">
        Selecione um Plano
      </div>
      <div className="signup-scroll-horizontal">
        {plans.map((_, i) => {
          return <div className="planWidget" tabIndex={0}>
            <div className="planHeader">
              <div className="planIconPlan" style={{ backgroundImage: `url(${imaPlan}` }}>
                <div className="planIconPlanText">{_.name}</div>
              </div>
              <div className="planHeaderTitle">
                <div className="planHeaderPrice">{_.exhibitionPrice}</div>
                <p className="planHeaderDiscount">{_.discount}</p>
              </div>
            </div>

            <div className="planDescription">
              {_.description}
            </div>

            <div className="planTitleBenefits">Conteúdo Disponível:</div>

            <div className="planBenefits">
              {_.benefits.map((b, e) => <div className="planBenefitsItem">
                <div className="planBenefitsItemTitle">{b.description}</div>
                <div className="planBenefitsItemIcon">{b.status ? <AiOutlineCheck style={{ color: "green" }} /> : <AiOutlineClose style={{ color: "red" }} />}</div>
              </div>
              )}

            </div>
            <div className="planDivBtn">
              {_.type != 'cupom' && <Button variant="success"
                style={{ color: "white", background: "rgb(109, 163, 27)", width: 150, height: 40, padding: 5, fontSize: 12 }} onClick={() => AddPlan(_)} >Contratar</Button>}



              {(_.type === "plan" || _.type === "cupom") &&
                <Button
                  variant="success"
                  style={{ color: "white", background: "#E6391A", width: 150, height: 40, padding: 5, fontSize: 12, border: 0 }}
                  onClick={async () => {
                    await CustomDialog(<Cupom plan={_} />, {
                      title: '',
                      showCloseIcon: false,
                      className: "CustomDialog"
                    });
                  }}
                >
                  CUPOM
                </Button>}


            </div>

          </div>
        })}

      </div>
    </div>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext)

  return (
    <div disabled={isFirstItemVisible} onClick={() => scrollPrev()}>

    </div>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext)

  return (
    <div disabled={isLastItemVisible} onClick={() => scrollNext()}>

    </div>
  );
}



export default App;







