
import React, { useState, useEffect } from 'react'
import { useAuth } from "../../contexts/AuthContext"
import api from '../../Services/Api'
import { Form, Button, InputGroup, Col, FloatingLabel, Row, Container, Alert, Image } from 'react-bootstrap'
import { Confirm, CustomDialog, useDialog, StaticDialog } from 'react-st-modal';
import Loading from '../../Components/Widgets/Loading'
import './Index.scss'


export default function Properties() {
    const { currentUser, addUser, splan, login } = useAuth()
    const [loading, setLoading] = useState(false)
    const [properties, setproperties] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [state, setState] = useState()
    const [city, setCity] = useState()
    const [add, setadd] = useState(true)
    const [activities, setActivities] = useState([])
    const [selectActivities, setSelectActivities] = useState([])
    const [name, setName] = useState("")
    const [validated, setValidated] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const dialog = useDialog();

    useEffect(() => {
        getState()
        getActivities()
    }, [])

  


    const getState = () => {
        api
            .get(`/user-api/states?s=120`)
            .then((response) => {
                console.log(response.data);
                setStates(response.data.data)
                setState(JSON.stringify(response.data.data[0]))
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
        // 
    }


    const getActivities = () => {
        api
            .get(`/user-api/activities?s=10`)
            .then((response) => {
                console.log(response.data);
                setActivities(response.data.data)

            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
        // 
    }



    useEffect(() => {
        const getCities = () => {
            let st = JSON.parse(state)
            api
                .get(`/user-api/states/${st.id}/cities?s=10000`)
                .then((response) => {
                    console.log(response.data);
                    setCities(response.data.data)
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
        }
        if (state) getCities()
    }, [state])





    const setAct = (e) => {
        let act = activities

        act[e]["check"] = act[e]["check"] ? !act[e]["check"] : true
        setActivities(act)
        console.log(act[e])

    }






    const handleSubmit = (event) => {
        console.log('entoru')
        setLoading(true)
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {

            event.stopPropagation();
            setLoading(false)

            return null
        } else {
            console.log('eee')
            console.log(name)

            let ct = JSON.parse(city)
            let st = JSON.parse(state)




            var currentActivities = activities.filter((item) => item.check == true);
            console.log(currentActivities)
            var cuur = currentActivities.map((item) => item.description)

            console.log(currentActivities, cuur)

            var data = {
                "name": name,
                "city": ct.name,
                "stateAbbr": `${st.name} - ${st.abbr}`,
                "activities": cuur
            };


            api
                .post(`/user-api/users/${currentUser.user.id}/properties/`, data)
                .then((response) => {
                    console.log(response.data)
                    dialog.close(true)
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });


            setValidated(true);
            setLoading(false)
        }
    }

    if (loading) return <Loading />


   

        return <div className="properties-add">
            <h3>Adicionar Propriedade</h3>
            <Form noValidate validated={validated} onSubmit={handleSubmit} >
                <Container>


                    <FloatingLabel controlId="floatingSelectGrid" label="Nome da Propriedade" className="g-2 vld-row">
                        <Form.Control size="sm" type="text" required onChange={(e) => setName(e.target.value)} placeholder="Nome da Propriedade" />
                    </FloatingLabel>

                    <Row className="g-2 vld-row">

                        <Col >
                            <FloatingLabel controlId="floatingSelectGrid" label="Estado / UF" >
                                <Form.Select aria-label="Estado /UF" size="sm" onChange={(e) => setState(e.target.value)} required >
                                    {states.map((_, key) => <option value={JSON.stringify(_)}>{_.name} - {_.abbr}</option>)}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="g-2 vld-row">
                        <Col>
                            <FloatingLabel controlId="floatingSelectGrid" label="Cidade" >
                                <Form.Select aria-label="Cidade" size="sm" onChange={(e) => setCity(e.target.value)} required>
                                    {cities.map((_, key) => <option value={JSON.stringify(_)}>{_.name}</option>)}
                                    {/* {cities.length == 0 &&<option>Seleciona um estado</option>}                           */}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>

                    <p className="properties-add-atv">Atividades</p>
                    <p className="properties-add-desc">Selecione as atividades de sua propriedade:</p>
                    <div>
                        {activities.map((_, a) => {
                            return <div> <Form.Check
                                type={'checkbox'}
                                id={`default-${_.description}`}
                                label={`${_.description}`}
                                onChange={() => setAct(a)}
                            /></div>
                        })}
                    </div>
                </Container>
                <Button type="submit"  >Salvar</Button>
            </Form>
        </div>
    


}
