import React, {useState, useEffect} from 'react'
import Modal from '../Modal/Modal'
import ModalAuth from '../User/AuthModal'
import { useAuth } from "../../contexts/AuthContext"
import { Form, Button, Image, Col, FloatingLabel, Row, Container } from 'react-bootstrap'
import './Auth.scss'

import {
  
 Link,useHistory
    
  } from "react-router-dom";

export default function Auth() {
    const [openModal, setOpenModal] = useState(false)
    const { login, currentUser, auth, logout } = useAuth()
    const { push } = useHistory()
        const setLogout = () =>{
            logout()
            window.location.reload(false);
        }

    return (
        <>
        <style type="text/css">
          {`
            .btn-flat {
              background-color: purple;
              color: white;
            }
  
            .btn-huge {
              padding: 5rem 10rem;
              font-size: 1.5rem;
            }
  
            .btn-small {
              padding: 0.1rem 0.1rem;
              font-size: 10px;
              
            }
          `}
        </style>
        <div className="auth">
                <Modal open ={openModal} close={(e)=> setOpenModal(e)} >
                    <ModalAuth  close={(e)=> setOpenModal(e)} /> 
                </Modal>
                {currentUser &&  <div>
                  <Button className="btn-small " style={{width:100, height:30 ,backgroundColor:"green"}}   onClick={() => push('/profile')} >Meu Perfil</Button>
                    {/* <div onClick={() => setLogout()} style={{cursor: 'pointer' }}>Sair</div> */}
                </div>
                 }



                {!currentUser && <Button className="btn-small auth-btn" style={{width:100, height:30 ,backgroundColor:"green", marginBottom:10, borderColor:"green"}} onClick={()=> setOpenModal(true)}>Entrar</Button>}
                {!currentUser &&  <Button className="btn-small  auth-btn" style={{width:100, height:30 ,backgroundColor:"#E6391A" , borderColor:"#aaa" }}   onClick={() => push('/signup')} >Criar conta</Button>
                }

               
              
        </div>
        </>
    )
}
