import React,{useEffect, useState} from 'react';

import './BarQuote.scss'
import { formatFloat, formatDate } from '../../vdl'
import ReactLoading from 'react-loading';


import {useUtils} from './../Hooks/SelectChart'

function Flash({data}) {
  const {selectQuote, setSelectQuote} = useUtils()


  function seta(i) {
    if (i == null) return <div ></div>
    if (i < 0) return <div className={`quseta qufontRed`}>▼</div>
    if (i > 0) return <div className={`quseta qufontGreen`}>▲</div>
    return <div></div>
}

 

  let init = data[0].dados
  console.log(data)
  return (
    <div className="cQuote">

      <div className="qBarQuote">
        {data.map(function(a,i){
          return (<div className="bar-quote-quadro"  onClick={()=>setSelectQuote(a.dados.cod)}>
            <div className="qTitle">{a.dados.cod.split("_")[2]}</div>
            {/* <div className="quult">{seta(a.dados.var)} {a.dados.ult}</div>    */}
            <div className="quult">{seta(a.dados.var)} {Number(a.dados.ult).toFixed(2)}</div>   
            </div>)
        })}       
      </div>
      <div className="bar-quote-info">Informações de  {formatDate(init.dia)}</div>
    </div>
  );
}




 


function LoadingQuotes({config}) {
  const [quotes, setQuotes] = useState();
  let ativos = config.quotes
  useEffect(() => {
      async function getNews(){
          let response = await fetch(`https://precos.api.datagro.com/quadros/?ativos=${ativos.toString()}&idioma=pt-br&cliente=a81574eb-06c1-11ed-bcba-02ebefa3b928`);
         let resJson = await response.json();
         console.log(resJson.ativos);
      setQuotes(resJson.ativos)
      }
      getNews()
  }, [config]);

if(quotes) return <Flash data={quotes} />
return   <ReactLoading type={'spokes'} color={'#000'} height={20} width={20} />
}





//https://precos.api.datagro.com/quadros/?ativos=D_PEPR_SP_BR,%20D_PEPR_GO_BR,%20D_PEPR_MG_BR,%20D_PEPR_MS_BR,%20D_PEPR_MT_BR&idioma=pt-br

export default LoadingQuotes;