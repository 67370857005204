import React, { createContext, useState, useEffect, useContext } from 'react';
export const UtilsContext = createContext({});

export const UtilsProvider: React.FC = ({ children }) => {
    const [selectQuote, setSelectQuote] = useState('D_PEPR_SP_BR');

useEffect(() => {
    console.log(selectQuote)
}, [selectQuote]);



    return (
        <UtilsContext.Provider
            value={{  selectQuote, setSelectQuote }}>
            {children}
        </UtilsContext.Provider>
    );
};

// Hook próprio
export function useUtils() {
    const context = useContext(UtilsContext);
    return context;
}

