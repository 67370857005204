import React, { useState, useEffect, Fragment, memo } from 'react'
import './MiniHome.scss'

import { formatDate, formatFloat, formatFloatLocale } from '../../vdl'

import {useUtils} from './../Hooks/SelectChart'

let MAX = 20
let COLUNA = '0'

function Quadro({ data, notLink, select, clic, redd}) {

   const {selectQuote, setSelectQuote} = useUtils()

    const [quote, setQuote] = useState({});


    useEffect(() => {
        async function getNews() {
            let response = await fetch(`https://precos.api.datagro.com/quadros/?idioma=pt-br&quadro=${data.ativos}&cliente=a81574eb-06c1-11ed-bcba-02ebefa3b928`);
            let resJson = await response.json();
            setQuote(resJson)
        }
        getNews()
    }, [data]);

    function sel(params){
        console.log(params, "dasf")
        return redd(params)
    }


    return (
        <div>
            <table className='mtable' >
                <tr className='mtitle'>
                    <th style={{  padding:0,paddingLeft: 5 }}>{quote.titulo}</th>
                    <th style={{  padding:0,textAlign: "right" }} ></th>
                    <th style={{  padding:0,textAlign: "right", paddingRight: 5 }}> {data.subtitle || 'Preço' }</th>
                </tr>

                {quote.ativos && quote.ativos.map(function (quadro, i) {
                    if (i < MAX) {
                        return <Tr data={quadro.dados} key={i} select={(a)=> sel(a)} config={data}/>
                    }
                })}
            </table>

        </div>
    )
}

function color(i: number) {
    if (i == null) return 'mfontGrey'
    if (i < 0) return 'mfontRed'
    if (i > 0) return 'mfontGreen'
    return 'mfontGrey'
}



function Tr({ data, notLink, select, config }) {


    const [quote, setQuote] = useState(data)


    function longos(d) {

        let longo = d ? d.replace(" - ", " <br /> ") : '';
        if (longo != '') {
            let lg = longo.split("<br />")


            if (!lg[1]) {
                let d = longo.split(" ");
                let da = ""

                for (let index = 0; index < d.length; index++) {
                    if (index == 4)
                        da = da + " " + d[index] + "<br />"
                    else
                        da = da + " " + d[index]
                }
                return da

            } else {
                return longo
            }
        } else {
            return longo
        }
    }
    return (
        <Fragment>
            <tr style={{ padding:0}} onClick={()=>select(quote.cod)}>
                <td className='mcod'> {quote.nome}</td>
                { config.var != false ? <td className={`mvar ${color(quote.var)}`}>  {formatFloat(quote.var, 2) || 0}% </td> : <td></td>}
                <td className='multimo'>{formatFloat(quote.ult, quote.decimais)}{config.after || ''}</td>
            </tr>

        </Fragment>
    )
}






//  function GetQuadros(props) {

//     const {data, error, notLink} = props

   

//     let qdrs = []
//     data.quadros.map(function (quadro, i) {
//         if (quadro.seq == 1)
//             qdrs.push([quadro])
//         else
//             if (quadro.coluna == data.quadros[i - 1].coluna)
//                 qdrs[parseInt(quadro.coluna) - 1].push(quadro)
//             else
//                 qdrs.push([quadro])

//     })


//     return (
//         <div>
//             <h3 light="true">{data.titulo }</h3>
//             <div className={styles.contener}>
//                 {

//                     qdrs.map(function (coll, i) {


//                         return <Collunn data={coll} key={i} coluna={qdrs.length}  notLink={notLink} />

//                     })}
//             </div>

//         </div>
//     )
// }


// function Collunn({ data, coluna, notLink= false }) {



//     return (
//         <div className={`${styles.quadro} ${ coluna==3? styles.market : ''}`}>
//             {data.map(function (coll, i) {
//                 return <Quadro data={coll} key={i} notLink={notLink} />
//             })}
//         </div>
//     )
// }











// function LoadingQuotes(props) {

//     const [quotes, setQuotes] = useState();

//     useEffect(() => {
//         async function getNews(){
//             let response = await fetch(`https://precos.api.datagro.com/quadros/?quadro=D_PEPR_SP_BR,D_PEPR_SP_BR,D_PEPR_SP_BR,D_PEPR_SP_BR,D_PEPR_SP_BR&idioma=pt-br`);
//              

//            let resJson = await response.json();
//            console.log(resJson.ativos);
//         setQuotes(resJson.ativos)
//         }
//         getNews()
//     }, [props]);

//   if(quotes) return <Flash data={quotes} />
//   return   <ReactLoading type={'spokes'} color={'#000'} height={20} width={20} />
//   }




function Q({ativos, click}) {
    function sel(params){
        console.log(params, "dasf")
        return click(params)
    }
    return (
        <div className='mcontener'>
            
            {ativos.map(function (a, i) {
                return <Quadro data={a} key={i} redd={(a)=>sel(a) } />
            })}
          
        </div>
    )
}

export default memo(Q)
// export { Quadro, GetQuadros }
