import React, {useState, useEffect} from 'react'

import Modal from 'react-modal';
import { AiOutlineCloseCircle } from "react-icons/ai";

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  

export default function ModalComponent({open, close, children,}){


    const [modalIsOpen, setIsOpen] = React.useState(false);

    useEffect(() => {
       console.log(open)
       setIsOpen(open)
    }, [open])

    function closeModal() {
        setIsOpen(false);
        close(false)
      }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
   
  }

    return (
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'flex-end', marginTop:-20}}>
        <AiOutlineCloseCircle onClick={closeModal} style={{right:0, position: 'relative',marginRight: -20, fontSize: 25, color: "#444"}}/>
        {/* <button onClick={closeModal} style={{right:0, position: 'relative',marginRight: -20 }}>X</button> */}
        </div>
         
        {children}
        </Modal>
   
    )
}
