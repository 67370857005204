
import React from 'react'


import './Clear.css';

const Clear = () => <span className="main"></span>

function autoClear(list, clearAtIndex, injectFn = null) {

    let listLength = list.length
    let l = Math.ceil(listLength / clearAtIndex)
    let hasInjectFunction = typeof injectFn === 'function'
    let newList = list.slice(0)

    for (let i = l; i > 0; i--) {

        let targetIndex = i * clearAtIndex
        let keySuffix = `clear${listLength}_${clearAtIndex}_${i}`

        if (targetIndex > newList.length)
            targetIndex = newList.length

        newList.splice(targetIndex, 0, <Clear key={`clear${keySuffix}`} />)

        if (hasInjectFunction)
            newList.splice(targetIndex + 1, 0, injectFn(keySuffix, i))

    }

    return newList
}

export default Clear
export { Clear, autoClear }
