import React, { useState, useEffect } from 'react'
import { Form, Button, InputGroup, Col, FloatingLabel, Row, Container, FormControl } from 'react-bootstrap'
import api from './../../Services/Api'
import { useAuth } from "../../contexts/AuthContext"


import Loading from '../../Components/Widgets/Loading'

    import { Link, useHistory } from "react-router-dom"

export  default function Index({next}) {
    const { addUser } = useAuth()
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false)
    const [cpf, setCpf] = useState('')
    const [eye, setEye] = useState(false)

   const history = useHistory()

    const [exibir, setExibir] = useState(false);
    const [error, setError] = useState({});


    
   
    


    const handleSubmit = (event) => {
        let e = {}
        setLoading(true)
       
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            if(password.length < 6) {
                e["e"] = "A senha deve ter pelo menos 6 caracteres"
                setError(e)
                setExibir(true)
            } else {
                setExibir(false)
            }
        
          event.stopPropagation();
          setLoading(false)

          return null
        }else{
            console.log('eee')
            var data = {
                "name": name,
                "email": email,
                "password": password,
                "phone": phone,
                "cpf": cpf,
                "profileImage": "",
                "role": "Cliente",
                "status": "Ativo",
            };
    
            api
                .post("/user-api/users", data)
                .then((response) => {
                    console.log(response)
                    response.data.data['pwd'] = password
                    addUser(response.data.data)
                    setLoading(false)
                    history.push("/plan")
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
    
           
            setValidated(true);
            setLoading(false)
        }

       

    }

  function mascararTel(v){
        v=v.replace(/\D/g,"");            
        v=v.replace(/^(\d{2})(\d)/g,"($1) $2"); 
        v=v.replace(/(\d)(\d{4})$/,"$1-$2");   
        setPhone(v)
    }

    if(loading) return <Loading />

  

    return (
        <div style={{
            height: 800,
            maxWidth: 500,
            textAlign: 'center',
            margin:' 0 auto'
        }}>
               
        <div className="signup-title">
            Cadastro
        </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Container>
           
          
                <FloatingLabel controlId="floatingSelectGrid" label="Nome Completo" className="g-2 vld-row"> 
                        <Form.Control size="sm" type="text"  required onChange={(e)=> setName(e.target.value)}  />
                </FloatingLabel>

                <FloatingLabel controlId="floatingSelectGrid" label="Email" className="g-2 vld-row"> 
                        <Form.Control size="sm" type="email"  required onChange={(e)=> setEmail(e.target.value)}  />
                </FloatingLabel>
    
                {exibir && 
                    <div class="alert alert-danger" role="alert">{error["e"]}</div>
                }
                <FloatingLabel controlId="floatingSelectGrid" label="Senha" className="g-2 vld-row"> 
                <Form.Control minLength="6" size="sm" type={`${eye? 'text': 'password'}`}  required onChange={(e)=> setPassword(e.target.value)}  />
                    <div className="search-icon" style={{position: 'absolute', right: 10, top: 15, zIndex: 9999}} onClick={()=> setEye(!eye)}>
                    <i class={`fas ${eye ? 'fa-eye': 'fa-eye-slash'}`}  />
                    </div>
                    <div class="form-text">mínimo de 6 caracteres</div>
                </FloatingLabel>
                <FloatingLabel controlId="floatingSelectGrid" label="Telefone" className="g-2 vld-row" id="phone"> 
                        <Form.Control size="sm" type="text" value={phone}  required onChange={(e)=> mascararTel(e.target.value)}  />
                </FloatingLabel>
                <FloatingLabel controlId="floatingSelectGrid" label="CPF: " className="g-2 vld-row" id="CPF"> 
                        <Form.Control size="sm" required type="text"  type="text" id="cpf" className="mb-3"  onChange={event => setCpf(event.target.value.replace(".", "").replace(".", "").replace("-", "")) }  value={cpf}/>
                </FloatingLabel>
 

                </Container>
                <Button type="submit"  variant="success" style={{width: '95%'}}  >Próximo</Button>
            </Form>
   


        </div>
    )
}
