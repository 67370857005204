import React from 'react'
import './TopBar.scss'
function TopBar({config}) {
    return (
        <div className="TopBar">
            <nav>
                <ul className="menu">
                    <li style={{ textAlign: "left" }}>
                        <img style={{ width: 20, height: 20 }} src="https://portal.datagro.com/favicon.ico" alt="Datagro Logo" />
                    </li>
                    <li>
                        <a href="#">Sobre a DATAGRO</a>
                        <ul>
                            <li><a href="https://portal.datagro.com/pt/about/company" target="_blank" rel="noopener noreferrer">Empresa</a></li>
                            <li><a href="https://portal.datagro.com/pt/about/team" target="_blank" rel="noopener noreferrer">Equipe</a></li>
                            <li><hr className="hrr" /></li>
                            <li><a href="https://portal.datagro.com/pt/about/privacy" target="_blank" rel="noopener noreferrer">Política de Privacidade</a></li>
                            <li><a href="https://portal.datagro.com/pt/about/terms" target="_blank" rel="noopener noreferrer">Termos de Uso</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href={config.topBar.metodologia} target="_blank" rel="noopener noreferrer">Metodologia do Indicador</a>
                    </li>
                    <li>
                        <a href="#">Ecossistema</a>
                        <ul>
                            <li><a href="http://benriratings.com.br" target="_blank" rel="noopener noreferrer">Benri</a></li>
                            <li><a href="http://datamaps.com.br" target="_blank" rel="noopener noreferrer">Datamaps</a></li>
                            <li><a href="http://deag.com.br" target="_blank" rel="noopener noreferrer">Deag</a></li>
                            <li><a href="http://firealert.com.br" target="_blank" rel="noopener noreferrer">FireAlert</a></li>
                            <li><a href="http://globalagribusinessforum.com" target="_blank" rel="noopener noreferrer">GAF</a></li>
                            <li><a href="http://geoflorestas.com.br" target="_blank" rel="noopener noreferrer">Geoflorestas</a></li>
                            <li><a href="http://hifarm.com.br" target="_blank" rel="noopener noreferrer">Hifarm</a></li>
                            <li><a href="http://plantproject.com.br" target="_blank" rel="noopener noreferrer">Plant Project</a></li>
                            <li><a href="http://geoflorestas.com.br/plataforma-renovar/" target="_blank" rel="noopener noreferrer">Renovar</a></li>
                            <li><a href="http://startagro.agr.br" target="_blank" rel="noopener noreferrer">Startagro</a></li>
                        </ul>
                    </li>
                    <li>
                        <a href={config.topBar.informe} target="_blank" rel="noopener noreferrer">Informe Pecuário Semanal</a>
                    </li>
                    <li>
                        <a href="https://portal.datagro.com/pt/contact" target="_blank" rel="noopener noreferrer">Contato</a>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default TopBar
