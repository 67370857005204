import React from 'react'
import './Header.css'
import { DownExcelFecfh,DownCsvFecfh} from './DownCsv'

function Header({ selectTime, changeTime, changeType, quote }) {

    function clickTime(time) {
        selectTime = time
        return changeTime(time)
    }

    function clickType(i) {
        return changeType(i)
    }

    return (

        <div className='menu2'>

        <ul style={{ minWidth: 80}}>
                <li onClick={() => clickType(1)}><a className='lineIcon' ></a></li>
                <li onClick={() => clickType(2)}><a className='candlesIcon' ></a></li>
            </ul>

            <ul style={{ minWidth: 230}}>
                <li onClick={() => clickTime('10D')}><a className={`${selectTime == '10D' ? 'select' : ''}`}>10D</a></li>
                <li onClick={() => clickTime('1M')}><a className={`${selectTime == '1M' ? 'select' : ''}`}>1M</a></li>
                <li onClick={() => clickTime('3M')}><a className={`${selectTime == '3M' ? 'select' : ''}`}>3M</a></li>
                <li onClick={() => clickTime('6M')}><a className={`${selectTime == '6M' ? 'select' : ''}`}>6M</a></li>
                <li onClick={() => clickTime('1Y')}><a className={`${selectTime == '1Y' ? 'select' : ''}`}>1Y</a></li>
                <li onClick={() => clickTime('5Y')}><a className={`${selectTime == '5Y' ?'select' : ''}`}>5Y</a></li>
            </ul>


            <div className='dowls' >
                <div className='dropdown'>
             <span>Baixar histórico</span>
            <div className='dropdownContent'>
            <DownExcelFecfh quote={quote} selectTime={selectTime} />
            <DownCsvFecfh quote={quote} selectTime={selectTime} />
            </div>
</div> 


            </div>

 
        </div>
    )
}

export default Header
