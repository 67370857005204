import React, { useState, useEffect } from 'react'
import moment from 'moment'
 import Chart from './ChartAnyChart'
import styles from './Chart.css'
import HeaderChart from './Header'
import Header from './Quote/Header'

const Index = React.memo(
    function Comp({ dados, cod, title = false, style, id }) {


       
    
        let st = style ? styles.chart1 : styles.chart
        const [time, setTime] = useState(null)
         const [type, setType] = useState(1)
         const [query, setQuery] = useState(1)
    
        useEffect(() => {
    
            let typeChart = dados.maxi === dados.mini ? 1 : 2
            setType(typeChart)
            let freq = dados.freq ? dados.freq : 'd'
            let dataNow = moment().format("YYYY-MM-DD")
            let dataMin = moment().subtract(3, 'months').format("YYYY-MM-DD")
            var qy = {
                'i': dataMin,
                'f': dataNow,
                'formato': 'json',
                'a': dados.cod,
                'periodo': freq,
                // 'bolsa': dados.idbolsa 
            }  
            if(dados.idbolsa) qy['bolsa'] = dados.idbolsa 
            setQuery(qy)
           
    
        }, [dados])
    
    
    
    
    
    
        useEffect(() => {
            let freq = dados.freq ? dados.freq : 'd'
            let dataNow = moment().format("YYYY-MM-DD")
            let dataMin = moment().subtract(3, 'months').format("YYYY-MM-DD")
            switch (time) {
                case '10D':
                    dataMin = moment().subtract(10, 'days').format("YYYY-MM-DD")
                    break;
                case '1M':
                    dataMin = moment().subtract(1, 'months').format("YYYY-MM-DD")
                    break;
                case '3M':
                    dataMin = moment().subtract(3, 'months').format("YYYY-MM-DD")
                    break;
                case '6M':
                    dataMin = moment().subtract(6, 'months').format("YYYY-MM-DD")
    
                    break;
                case '1Y':
                    dataMin = moment().subtract(1, 'years').format("YYYY-MM-DD")
    
                    break;
                case '5Y':
                    dataMin = moment().subtract(5, 'years').format("YYYY-MM-DD")
                    break;
            }   
            var qy = {
                'i': dataMin,
                'f': dataNow,
                'formato': 'json',
                'a': dados.cod,
                'periodo': freq,
                // 'bolsa': dados.idbolsa
            }
            if(dados.idbolsa) qy['bolsa'] = dados.idbolsa 
            if(dados.cod != undefined) return setQuery(qy)
            
        }, [time])
    
    
    
    
    
    
    
        return (
            <div className={st} >
    
                {title &&
                    <div style={{ textAlign: 'center', marginBottom: 2, marginTop: 5 }}>
                        <h4>{dados.nome}</h4>
                        <a style={{ fontSize: 10, textAlign: 'center' }}>{dados.longo}</a>
    
                    </div>
                }
    
                 {/* <Header ativo={dados} />  */}
                 <HeaderChart quote={dados.cod} selectTime={time} changeTime={setTime} changeType={setType} /> 
                 <Chart type={type} query={query} id={id} /> 
            </div>
        )
    }
    
  



    );





    export default Index
    
