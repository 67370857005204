import React, { useState, useEffect } from 'react'
import Header from './Header'
import api from '../../Services/Api'
import InfiniteScroll from 'react-infinite-scroll-component';
import Image from 'react-bootstrap/Image'


import Lottie from 'react-lottie';

import imgCoin from './../../Assets/icons/coin-icon.svg'
import imgLoader from './../../Assets/animations/splash_screen.json'

import Neg from './../../Screens/Negociocion'

import { AiOutlineFilter } from "react-icons/ai";
import { Form, Button, InputGroup, Col, FloatingLabel, Row, Container, Modal} from 'react-bootstrap'

function Mdl(props) {

    const [lcl, setLcl] = useState([]);
    const [categories, setCategories] = useState([])

    useEffect(() => {
        getLcl()
        getCategories()
    }, [])



    const getLcl = () => {
        api
        .get(`/user-api/states?s=120`)
        .then((response) => {
                // console.log("-------------------esse mesmo-----------------");
                console.log(response.data);
                setLcl(response.data.data);
        })
        .catch((err) => {
            console.error("ops! ocorreu um erro" + err);
        });
    }


    
    const getCategories = () => {
        api
        .get(`/user-api/categories`)
        .then((response) => {
            console.log(response.data)
            setCategories(response.data.data)
        })
        .catch((err) => {
            console.error("ops! ocorreu um erro" + err);
        });
    }

    const [fav, setFav] = useState({
        "category": "null",
        "period": "null",
        "UF": "null",
        // "favorite": false,
    })

    const generatorFilter = e => {
        const { name, value } = e.target;
        name != "favorite" ? setFav({...fav,[name]: value}) : setFav({...fav,[name]: !fav["favorite"]});
    };

    return (
    <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Filtrar
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <Form>
                <Container>
                    <Row className="g-2 vld-row">
                        <Col xs={12}>
                            <FloatingLabel label="Categoria">
                                <Form.Select name="category" onChange={generatorFilter} defaultValue={fav.category}>
                                    <option value="null">Selecione a categoria</option>
                                    {categories.map((_, key) => <option value={_}>{_}</option>)}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="g-2 vld-row">
                        <Col xs={12}>
                            <FloatingLabel label="Periodo">
                                <Form.Select name="period" onChange={generatorFilter} defaultValue={fav.period}>
                                    <option value="0">Selecione o periodo</option>
                                    <option value="7">7 dias</option>
                                    <option value="15">15 dias</option>
                                    <option value="30">1 mês</option>
                                    <option value="90">3 meses</option>
                                    <option value="180">6 meses</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="g-2 vld-row">
                        <Col xs={12}>
                            <FloatingLabel label="Local">
                                <Form.Select name="UF" onChange={generatorFilter} defaultValue={fav.UF}>
                                    <option value="null">Selecione um local</option>
                                    {lcl.map((_, key)=> <option value={_.abbr} >{_.name} - {_.abbr}</option>)}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Container>
            </Form>

            {/* <Form.Check type="checkbox" label="Favoritos" name="favorite" onChange={generatorFilter}/> */}

        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={()=>{props.returnModal(fav); props.onHide()}}>Filtrar</Button>
        </Modal.Footer>
    </Modal>
    );
}  

function News({ data, ...props }) {
     const [modalShow, setModalShow] = React.useState(false);

    const [items, setItem] = useState(data.data)
    const [lastId, setlastId] = useState(data.meta.currentLastId)
    const [openModal, setOpenModal] = useState(false)
    const [itemModal, setitemModal] = useState()

    const [filterData, setFilterData] = useState([]);
    const [filterOnOff, setFilterOnOff] = useState(false);

    const filter = async (a) => {

        console.log(a)
        // await setlastId('')
         await setFilterData({type:a.category,period:a.period,originUF:a.UF}); 
        // await  setItem([])
        // // a.category == "null" && a.period == "null" && a.UF == "null" ? setFilterOnOff(false) : setFilterOnOff(true)
         await  fetchMoreData({category:a.category,period:a.period,place:a.UF})
       
    }

    const fetchMoreData = (filterr=false) => {
        // a fake async api call like which sends
        // 20 more records in 1.5 secs
        let lstId = lastId
        let listitem = items
        var str = ''

        if(filterr){
            let params = filterr
            console.log('entrou no filter')
           

    
            lstId=''
            listitem=[]
            console.log('entrou no filter')
            if(params.period){
                console.log(params)
                new Date().valueOf() 
                var d = new Date();
                d.setDate(d.getDate()-filterr.period);
                
                console.log(d)


                var today = new Date();
                today.setDate(today.getDate()-filterr.period);
var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
var time = today.getHours()+':'+today.getMinutes()+':'+today.getSeconds();
params.sd = today.valueOf()
params.ed = new Date().valueOf() 



console.log(params)



            }





            str = Object
            .keys(params)
            .map(k => k + '=' + params[k])
            .join('&');
        

            console.log(`/publication-api/posts?s=15&tf=timeline_negotiations&a=${lstId}&${str}`)
        }

        api
        .get(`/publication-api/posts?s=15&tf=timeline_negotiations&a=${lstId}&${str}`)
        .then((response) => {
            setItem(listitem.concat(response.data.data))
            setlastId(response.data.meta.currentLastId)
        })
            
        .catch((err) => {
            console.error("ops! ocorreu um erro" + err);
        });

            // setTimeout(() => {
            //     setItem(items.concat(Array.from({ length: 20 })))

            // }, 1500);

        // if(filterOnOff == false) {
        //     api
        //         .get(`/publication-api/posts?s=15&tf=timeline_negotiations&a=${lastId}`)
        //         .then((response) => {
        //             setItem(items.concat(response.data.data))
        //             setlastId(response.data.meta.currentLastId)
        //         })
                
        //         .catch((err) => {
        //             console.error("ops! ocorreu um erro" + err);
        //         });

        //     // setTimeout(() => {
        //     //     setItem(items.concat(Array.from({ length: 20 })))

        //     // }, 1500);
        // } else {

        //     // filter
        //     var fill = "";
        //     if(filterData[0] != "null"){
        //         fill = "a.content.category == filterData[0]"
        //     }
        //     if(filterData[1] != "null"){
        //         fill == "" ? fill = "dataPdr - dataSet < dataObj" : fill += " && dataPdr - dataSet < dataObj"
        //     }
        //     if(filterData[2] != "null"){
        //         fill == "" ? fill = "a.content.originUF == filterData[2]" : fill += " && a.content.originUF == filterData[2]"
        //     }

        //     api
        //         .get(`/publication-api/posts?s=15&tf=timeline_negotiations&a=${lastId}`)
        //         .then((response) => {
                    
        //             var itm = []

        //             response.data.data.forEach(a => {

        //                 var dataPdr = new Date();
        //                 dataPdr = dataPdr.getTime();
                        
        //                 var dataSet = parseInt(filterData[1]);
                        
        //                 var dataObj = new Date(a.updateDate);
        //                 dataObj = dataObj.getTime();
                        
        //                 if(eval(fill)){
        //                     itm.push(a)
        //                     console.log(a)
        //                 }
        //             });

        //             setItem(items.concat(itm))
        //             // setItem(items.concat(response.data.data))
        //             setlastId(response.data.meta.currentLastId)
        //         })
                
        //         .catch((err) => {
        //             console.error("ops! ocorreu um erro" + err);
        //         });
        // }

    };

    const ViewModalOpen = (item) => {
        setitemModal(item)
        setOpenModal(true)
    }

    return (
        <div className="clientWidget">
            <Header title="Negociação" color="green" width={ <>
                <Neg />
                <Button
                className="btn-circle"
                onClick={() => setModalShow(true)}
                variant="success"
                style={{ color: "white", margin:10 }}
            ><AiOutlineFilter/></Button>
             
            </> } />

            <ViewModal data={itemModal} openModal={openModal} closeModal={()=> setOpenModal(false)} />
            <div className="opac" style={{ display: openModal ? 'block' : 'none' }} onClick={()=> setOpenModal(false)}></div>

            <>
             
                <Mdl show={modalShow} onHide={() => setModalShow(false)} returnModal={(a)=>filter(a)}/>
            </> 

            <div
                id="scrollableDivNegotiations"
                style={{
                    height: '90%',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#eee'
                }}
                >

                {/* Comentar --> Put the scroll bar always on the bottom */}
                {items &&
                    <InfiniteScroll
                        dataLength={items.length}
                        next={fetchMoreData}
                        style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
                        inverse={false} //
                        hasMore={true}
                        loader={<h4>Loading...</h4>}
                        scrollableTarget="scrollableDivNegotiations"
                    >
                        
                        {/* {items.map((_, index) => <New data={_} key={index} click={(item) => ViewModalOpen(item)} />)} */}
                        {items.map((_, index) => <New data={_} key={index} click={(item) => ViewModalOpen(item)} />)}
                    </InfiniteScroll>
                }
            </div>
        </div>
    )
}



function New({ data, click }) {
    var item = data.content?.originUF ?? '';
  
    var temp = item.split(" - ");

    var uf = temp.length == 2 ? temp[1] : '';
    const HeaderNegotiatons = () => <div onClick={() => click(data)} style={{ display: 'flex' }}>
        <Image src={data.profileImage} roundedCircle width={30} height={30} />
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5 }}>
            <div > <b>{getNameFromString(data.name)} </b> -  {new Date(data.creationDate).toLocaleString()}</div>
            <div style={{ color: 'rgba(100, 157, 63, 1)', fontSize: 10 }}>{data.content.originCity} - {uf}</div>
        </div>
       
    </div>
    return <div className="widgerPostNegotiations"  >
        <HeaderNegotiatons />
        <div onClick={() => click(data)} style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 , marginBottom:10}}>
            <p style={{ fontWeight: 666 }}>{`Negociação ${data.content?.status ?? ""}!`}</p>
            <div style={{ color: 'rgba(100, 157, 63, 1)' }}>
                <Image src={imgCoin} roundedCircle width={25} height={25} /> {`${data.content?.type ?? ""}`}
            </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Footer />
            <div>
                <div>Valor Base</div>
                <div style={{ fontWeight: 666 }}>{data.content?.value ?? "R\$ 0,00"}</div>
            </div>
            <div className="widgerPostFooterDivImg">
                <Image src={data.content.image} className="widgerPostFooterImg" />
            </div>
        </div>
    </div>

}



function ViewModal({data, openModal, closeModal}){

    if(!openModal) return <div />

    var item = data.content?.originUF ?? '';

    var temp = item.split(" - ");

    var uf = temp.length == 2 ? temp[1] : '';

    const getStateAbbr = (state) => {
        var temp = state.split(" - ");
        return temp.length == 2 ? temp[1] : '';
    }

    


    const HeaderNegotiatons = () => <div style={{ display: 'flex' }}>
        <Image src={data.profileImage} roundedCircle width={30} height={30} />
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5 }}>
            <div style={{ fontWeight: 666 }}>{getNameFromString(data.name)}</div>
            <div style={{ color: 'rgba(100, 157, 63, 1)', fontSize: 10 }}>{data.content.originCity} - {uf}</div>
        </div>
    </div>
    return  <div className="widgetMod" style={{ display: openModal ? 'block' : 'none' }}>
    <a onClick={() => closeModal(false)} title="Fechar" className="widgetFechar"  >x</a>
   
    <div className="widgerPostNegotiations"  >
        <HeaderNegotiatons />
        <div  style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
            <p style={{ fontWeight: 666 }}>{`Negociação ${data.content?.status ?? ""}!`}</p>
            <div style={{ color: 'rgba(100, 157, 63, 1)' }}>
                <Image src={imgCoin} roundedCircle width={25} height={25} /> {`${data.content?.type ?? ""}`}
            </div>
        </div>


        <div className="widgerPostItems">

            <div className="widgerPostItem">
                  <p className="widgerPostTitle">Quantidade:&nbsp; </p>
                  <p  className="widgerPostDescrition"> {`${data.content?.quantity ?? ""}`}</p>
            </div>
            <div className="widgerPostItem">
                  <p className="widgerPostTitle">Frete: &nbsp;</p>
                  <p  className="widgerPostDescrition">{`${data.content?.freight ?? ""}`}</p>
            </div>
            <div className="widgerPostItem">
                  <p className="widgerPostTitle">Bonificações:&nbsp; </p>
                  <p  className="widgerPostDescrition">{data.content?.bonus
                    ? data.content?.bonus.replaceAll(';', ', ')
                    : 'Nenhuma'}</p>
            </div>
            <div className="widgerPostItem">
                  <p className="widgerPostTitle">Valor Bonificação:&nbsp; </p>
                  <p  className="widgerPostDescrition">{(data.content?.bonusValue ?? "")
                    ? data.content?.bonusValue
                    : 'Nenhuma'}</p>
            </div>
            <div className="widgerPostItem">
                  <p className="widgerPostTitle">Prazo de Pagamento:&nbsp; </p>
                  <p  className="widgerPostDescrition">{`${data.content?.payment ?? ""}`}</p>
            </div>
             <div className="widgerPostItem">
                  <p className="widgerPostTitle">Origem: &nbsp;</p>
                  {data.content?.squareOrigin ?
                  <p  className="widgerPostDescrition">Praça&nbsp;{`${data.content?.squareOrigin}`}</p>

                  :
                  <p  className="widgerPostDescrition">{`${getStateAbbr(data.content?.originUF)}`}</p>
}
            </div> 

                 <div className="widgerPostItem">
                  <p className="widgerPostTitle">Destino: &nbsp;</p>
                  {data.content?.squareDestination? <p  className="widgerPostDescrition">Praça&nbsp;{`${data.content?.squareDestination}`}</p>
                  :
                  <p  className="widgerPostDescrition"> {`${getStateAbbr(data.content?.destinationUF)}`}</p>}
                 
            </div> 

          {/* (data.content?.slaughterHouse ?? "") &&
            <div className="widgerPostItem">
                  <p className="widgerPostTitle">Indústria/Abatedouro:&nbsp; </p>
                  <p  className="widgerPostDescrition">{data.content?.slaughterHouse ?? ""}</p>
            </div> 
             */}

            <div className="widgerPostItem">
                  <p className="widgerPostTitle">Pesagem:&nbsp; </p>
                  <p  className="widgerPostDescrition">{data.content?.weightMode ?? ""}</p>
            </div>

            { data.content?.weightMode == 'Peso Vivo' &&
            <div className="widgerPostItem">
                  <p className="widgerPostTitle">Porcentagem:&nbsp; </p>
                  <p  className="widgerPostDescrition">{data.content?.weigthPercent ?? "0%"}</p>
            </div>
            }

            <div className="widgerPostItem">
                  <p className="widgerPostTitle">Funrural:&nbsp; </p>
                  <p  className="widgerPostDescrition">{data.content?.funrural ?? ""}</p>
            </div>

            <div className="widgerPostItem">
                  <p className="widgerPostTitle">Nutrição: &nbsp;</p>
                  <p  className="widgerPostDescrition">{data.content?.nutrition ?? ""}</p>
            </div>
            <div className="widgerPostItem">
                  <p className="widgerPostTitle">Categoria:&nbsp; </p>
                  <p  className="widgerPostDescrition">{data.content?.category ?? ""}</p>
            </div>
            <div className="widgerPostItem">
                  <p className="widgerPostTitle">Raça:&nbsp; </p>
                  <p  className="widgerPostDescrition">{data.content?.breed ?? ""}</p>
            </div>
            <div className="widgerPostItem">
                  <p className="widgerPostTitle">Data da Negociação: </p>
                  <p  className="widgerPostDescrition">{data.content?.slaughterDate ?? ""}</p>
            </div>
            <div className="widgerPostItem">
                  <p className="widgerPostTitle">Data da Abate: &nbsp;</p>
                  <p  className="widgerPostDescrition">{data.content?.tradingDate ?? ""}</p>
            </div>

        </div>


        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Footer />
            <div>
                <div>Valor Base</div>
                <div style={{ fontWeight: 666 }}>{data.content?.value ?? "R\$ 0,00"}</div>
            </div>
            <div className="widgerPostFooterDivImg">
                <Image src={data.content.image} className="widgerPostFooterImg" />
            </div>
        </div>
    </div>
    </div>
}




function Footer({item}){
    return <div></div>
}


function getNameFromString(i) {
 
    if (i) {
        var names = i.split(" ");
        return names.length > 1 ? names[0] : names;
    }
    return "Anônimo";
}


// senha
// chamada
// firebase
// cookpit
// if plan
// vitorio












function GetInitial(props) {
    const [dados, setDados] = useState()



    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: imgLoader,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    

     


    useEffect(() => {
        api
            .get("/publication-api/posts?s=15&tf=timeline_negotiations")
            .then((response) => setDados(response.data))
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);

    
    if (!dados) return <div className="clientWidget">
         <div>
        <Lottie 
          options={defaultOptions}
          height={400}
          width={400}
        />
      </div>
    </div>

    return <News data={dados} props />

}

export default GetInitial
