import React, { useState, useEffect }from 'react';


import './Index.scss'


import Form from './Form'
import Plan from './Plan'
import Terms from './Terms'
import Methods from './Methods'
import PersonalInformation from './PersonalInformation'
import Address from './Address'
import Card from './Card';
import Checkout from './Checkout'
import Properties from './Properties'


const Example = () => {
    const [step, setStep] = useState(0)


    const skip = ({ step, push }) => {
        console.log('asdfasdfasdfasdfasdf', step, push )
        // switch (step.id) {
        //   case 'gandalf': {
        //     push('ice-king');
        //     break;
        //   }
        //   default:
        //     push();
        // }
      };
    


    return (<div className="signout-content">

       {/* free */}
       {step == 0 &&  <Form next={(a)=> setStep(a)} />}
       {step == 1 &&  <Plan next={(a)=> setStep(a)} />}
       {step == 2 &&  <Terms next={(a)=> setStep(a)} />}


       {step == 3 &&  <Methods next={(a)=> setStep(a)} />}
       {step == 4 &&  <PersonalInformation next={(a)=> setStep(a)} />}

       {step == 5 &&  <Address next={(a)=> setStep(a)} />}
       {step == 6 &&  <Card next={(a)=> setStep(a)} />}
       {step == 7 &&  <Checkout next={(a)=> setStep(a)} />}

       {/* Properties */}
       {step == 8 &&  <Properties next={(a)=> setStep(a)} />}












{/* 
        
        <Wizard
         onNext={skip}>
            <Steps>
                <Step
                    id="merlin"
                    render={({ next }) => (
                        <div>

                            <Form next={(s)=> Próximo(s)} />

                        </div>
                    )}
                />
                <Step
                    id="gandalf"
                    render={({ next }) => (
                        <div>
                            <Plan next={next} />
                        </div>
                    )}
                />
                <Step
                    id="term"
                    render={({ next }) => (
                        <div>
                            <Terms next={next} />
                        </div>
                    )}
                />
                <Step
                    id="methods"
                    render={({ next }) => (
                        <div>
                            <Methods next={next} />
                        </div>
                    )}
                />
                <Step
                    id="personal"
                    render={({ next }) => (
                        <div>
                            <PersonalInformation next={next} />
                        </div>
                    )}
                />
                <Step
                    id="address"
                    render={({ next }) => (
                        <div>
                            <Address next={next} />
                        </div>
                    )}
                />
                <Step
                    id="card"
                    render={({ next }) => (
                        <div>
                            <Card next={next} />
                        </div>
                    )}
                />
                <Step
                    id="checkout"
                    render={({ next }) => (
                        <div>
                            <Checkout next={next} />
                        </div>
                    )}
                />
                <Step
                    id="dumbledore"
                    render={({ previous }) => (
                        <div>
                            <h1>Dumbledore</h1>
                            <button onClick={previous}>Previous</button>
                        </div>
                    )}
                />
            </Steps>
        </Wizard> */}
    </div>)
};

export default Example;