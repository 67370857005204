import React, { useState, useEffect } from 'react'


import styles from './Header.css'
import './Header.css'


import { formatFloat, formatDate } from '../../../vdl'

function Header(props) {



    const [quote, setQuote] = useState(props.ativo)
    const [ws, setWs] = useState({})

    let cod = props.ativo.cod


    function clickDesktop() {
        return props.click(quote)
    }

    function color(i) {
        if (i == null) return 'hfontGrey'
        if (i < 0) return 'hfontRed'
        if (i > 0) return 'hfontGreen'
        return 'hfontGrey'
    }



    function seta(i) {
        if (i == null) return <div ></div>
        if (i < 0) return <div className={`hseta hfontRed`}>▼</div>
        if (i > 0) return <div className={`hseta hfontGreen`}>▲</div>
        return <div></div>
    }



    let space = props.rvEspace ? 'hresetBox' : 'hbox'
    let spaceQuote = props.rvEspace ? 'hresetQuote' : 'hquote'


    function t(params) {
        return params
    }



    return (
        <div>
            <div className={'hlongo'} >
                {quote.longo}
            </div>
            <div className={'hheader'}>

                {/* <div className={spaceQuote} >

                    <div className={'hult'}>
                        <div className={'hseta'} >{seta(quote.var)}</div> {formatFloat(quote.ult, quote.decimais)}

                        <div className={'htxt'} > Ultima Atualização: {formatDate(quote.dia)}


                        </div>

                    </div>
                </div> */}
                {/* <div className={'hqs'}>
                    <div className={'hdados'}>

                        <div className={`${space}`} >
                            <div className={`hvar ${color(quote.var)}`}>{formatFloat(quote.var, 2) || 0}%</div>
                            <div className={'htxt'}>{t('var')} ({t('day')})</div>
                        </div>

                        <div className={`${space}`} >
                            <div className={'hmini'}>{formatFloat(quote.mini, quote.decimais)}</div>
                            <div className={'htxt'} >{t('lowFull')} ({t('day')})</div>
                        </div>

                        <div className={`${space}`} >
                            <div className={'hmini'}>{formatFloat(quote.maxi, quote.decimais)}</div>
                            <div className={'htxt'} >{t('highFull')} ({t('day')})</div>
                        </div>

                        <div className={`${space}`} >
                            <div className={'hmini'}>{formatFloat(quote.abe, quote.decimais)}</div>
                            <div className={'htxt'} >{t('open')} ({t('day')})</div>
                        </div>


                    </div>

                </div> */}

            </div></div>
    )
}

export default React.memo(Header)
