import React,{useState, useEffect} from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams
  } from "react-router-dom";
  import api from '../Services/Api'
  import Image from 'react-bootstrap/Image'
  import DOMPurify from 'dompurify';
  import Sidebar from './../Components/Home/SidebarNews'

  import './ReadNews.scss'

export default function ReadNews() {
    let { id } = useParams();
    const [news, setNews] = useState()

    useEffect(() => {
       
    const getNews = () => {
        try {
            api
                .get(`/publication-api/publications/${id}`)
                .then((response) => {
                    setNews(response.data.data)
                    console.log(response.data.data)
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
        } catch (error) {
            console.error("ops! ocorreu um erro" + error);
        }
    }
    getNews()
    }, [id])
  


    if(!news) return <div>Carregando</div>


    const HeaderNew =()=><div style={{marginBottom:10}}>  <Image src={news.profileImage} roundedCircle width={25} height={25} style={{margin:5}}/>
    {news.name} - {new Date(news.creationDate).toLocaleString()}</div>

    return (
        <div className="news-box">
             
        <div className="news-content">
        <h3>{news.content?.title}</h3>
              <HeaderNew />
              {news.content?.media_type === "image" &&  <Image src={news.content.media} fluid style={{width: "100%", height: 250, objectFit: "cover"}}/> }
              <div  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(news.content?.body)}}></div>
        </div>

              <div className="news-sidebar">
              <Sidebar />
              </div>
        </div>
    )
}
