import React, {useState, useEffect}from 'react'
import api from '../../Services/Api'
import { useAuth } from "../../contexts/AuthContext"
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import moment from "moment";
import { Link, useHistory } from "react-router-dom"
import './Index.scss'
export default function Terms({next}) {

    const [terms, setTerms] = useState({})
    const [loading, setLoading] = useState(true)
    const { currentUser, splan } = useAuth()

    const history = useHistory()
    const [checkTerms, setCheckTerms] = useState(false)
    const [checkPolity, setCheckPolity] = useState(false)

    useEffect(() => {

      console.log(splan)

        const GetPlan =() => {
            api
            .get(`/user-api/terms`)
            .then((response) => {
                    console.log(response.data?.data[0]);
                    setTerms(response.data?.data[0])
                    setLoading(false)
                    
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
        }

        GetPlan()
       
    }, [])


    async function addUserSubscription(){

        let data = moment().format("yyy-MM-DDTHH:mm:ss");
        api
            .post(`/user-api/users/${currentUser.user.id}/signatures`, {termId: terms.id, date:data })
            .then((response) => {
                console.log(response.data.data)
                localStorage.setItem('TERMS', JSON.stringify(response.data.data));
                history.push("/methods")      
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    
    
    }



if(loading) return <div>Carregando</div>

    return (
        <div className="signup-content">
        <div className="signup-title">
            Termos de Uso
        </div>

           
            <div  style={{whiteSpace: "pre-wrap", margin: 20, overflow: 'auto', height: 500, border: '1px solid #ccc', textAlign: "left", padding: 10}}>
              {terms.content}
            </div>

            <Form>
            <Form.Group className="mb-3" controlId="formBasicCheckbox2">
                    <Form.Check type="checkbox" label="Li e aceito os termos uso" onChange={()=> setCheckTerms(!checkTerms)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCheckbox3">
                    <Form.Check type="checkbox" label="Li e aceito as políticas de privacidade" onChange={()=> setCheckPolity(!checkPolity)} />
                </Form.Group>
                
                </Form>

                <Button variant="primary" type="button" className="btn-dl-green" onClick={()=>addUserSubscription()} style={{width: 100}} disabled={checkPolity && checkTerms ? false: true}>
                    Aceitar
                </Button>
           
        </div>
    )
}


// navigateToNextPage() {
//     var planType = appController.currentPlan?.type ?? "";
//     switch (planType) {
//       case 'free':
//         getTip();
//         break;
//       case 'cupom':
//         Modular.to.popAndPushNamed('/signup/properties');
//         break;
//       default:
//         Modular.to.popAndPushNamed('/signup/payment-methods');
//         break;
//     }
//   }

//   getTip() async {
//     EasyLoading.show();
//     var response = await api.getTip();
//     EasyLoading.dismiss();
//     if (response.error) {
//       print(response.errorMessage);
//       Modular.to.popAndPushNamed('/home');
//     } else {
//       Modular.to.popAndPushNamed('/tipOfTheDay', arguments: response.data);
//     }
//   }