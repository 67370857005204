import React, {useEffect, useState} from 'react';

import './Quadro.css'
import Minihome from './MiniHome'
import Chart from './Chart'

function Quadro({id, data}) {

console.log({id, data})
  const [ativo, setAtivo] = useState(data.default);

  if(!data) return null
  return (
    <div className="cQuadro">
     {data.title != ''&&  <h5 style={{marginLeft:14, fontWeight: 'bold'}}>{data.title}</h5>}
      <div className="cList">
        <Minihome ativos = {data.quadro1}  click={(a)=> setAtivo(a)}/>
        <Minihome ativos = {data.quadro2}  click={(a)=> setAtivo(a)}/>
      </div>
      <div className="cChart" ><Chart  ativo={ativo} id={id} /></div>

    </div>
  );
}

export default Quadro;