import React from 'react'
import imgLoader from './../../Assets/animations/splash_screen.json'
import Lottie from 'react-lottie';



export default function Loading() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: imgLoader,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

    return (
        <div>
        <Lottie 
          options={defaultOptions}
          height={400}
          width={400}
        />
      </div>
    )
}
