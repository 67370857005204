import React, { useRef, useState } from "react"

import { useAuth } from "../../contexts/AuthContext"
 import { Link, useHistory} from "react-router-dom"
 import api from './../../Services/Api' 
import imgBalizador from './../../Assets/balizador-gpb-datagro.png'

import { Form, Button, InputGroup, Col, FloatingLabel, Row, Container, Card, Alert } from 'react-bootstrap'


export default function Login({close, redirect, msg = ''}) {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login} = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  let history = useHistory();

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError("")
      setLoading(true)
     await login(emailRef.current.value, passwordRef.current.value)
     if(redirect){
      history.push(redirect)
     }
   

     close(false)
  
    } catch {
      setError("Email ou Senha invalidos")
    }

    setLoading(false)
  }


 

  return (
    <>
      <Card style={{border: 0}}>
        <Card.Body >
          <div className='logo' style={{ marginBottom: 10 }}>
                <img src={'https://portal.datagro.com/gpb/config/imagens/logos/logo-header.png'} className="logoDatagro" />

            </div>

            
         
             <div style={{ padding:10, textAlign:"center"  }}>   
             <h5> {msg}</h5>
             </div>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            
               <FloatingLabel controlId="floatingSelectGrid" label="Email" className="vld-row input-sm" id="email"> 
                        <Form.Control size="sm" type="email" ref={emailRef} required className="input-sm" />
                </FloatingLabel>


                <FloatingLabel controlId="floatingSelectGrid" label="Senha"  id="password"> 
                        <Form.Control size="sm" type="password" ref={passwordRef} required  />
                </FloatingLabel>



            <Button disabled={loading} className="w-100" type="submit" style={{marginTop: 20}} className="btn-dl-green">
              Entrar
            </Button>
          </Form>
          {/* <div className="w-100 text-center mt-3">
             <p>Esqueceu sua senha?</p> 
          </div> */}
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
       Novo Usuário? <Link to="/signup" onClick={()=>close(false)}>Cadastre-se</Link> 
      </div>
    </>
  )
}
