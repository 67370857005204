import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"

import ModalAuth from '../../Components/User/AuthModal'

export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  async function handleSubmit(e) {
    e.preventDefault()
    try {
      setError("")
      setLoading(true)
     let user =  await login(emailRef.current.value, passwordRef.current.value)
     console.log(user)
      // history.push("/")
    } catch {
      setError("Email ou Senha invalidos")
    }

    setLoading(false)
  }

  return (
    <>

     <ModalAuth  redirect={'/app/plan'} msg={'Vamos iniciar a troca do seu plano. Por segurança confirme seu nome e senha.'}/>
    </>
  )
}
