
import React from "react";
import { Route, BrowserRouter } from "react-router-dom";

import App from './Screens/Home'
import User from './Screens/User'
import Login from './Screens/Login'
import Profile from './Screens/Profile'
import Signup from './Screens/Signup/Index'
import Plan from './Screens/Signup/Plan'
import Term from './Screens/Signup/Terms'
import Checkout from './Screens/Signup/Checkout'
import Methods from './Screens/Signup/Methods'
import PersonalInformation from './Screens/Signup/PersonalInformation'
import Address from './Screens/Signup/Address'
import Card from './Screens/Signup/Card.js'
import Properties from './Screens/Signup/Properties'
import ReadNews from "./Screens/ReadNews";
import PlanAuth from './Screens/Plans/Login'
import PlanUser from './Screens/Plans/Plans'
import AlterUser from './Screens/Plans/Plan'
import AlterMethods from './Screens/Plans/Methods'
import AlterAddress from './Screens/Plans/Address'
import AlterCard from './Screens/Plans/Card'
import AlterCheckout from './Screens/Plans/Checkout'


import TestFecth from './teste'






const Routes = () => {
   return(
       <div>
           <Route component = { App }  path="/" exact />
           <Route component = { Profile }  path="/profile" />
           <Route component = { PlanAuth }  path="/app/auth" />
           <Route component = { PlanUser }  path="/app/plan" />
           <Route component = { AlterUser }  path="/app/alter" />
           <Route component = { AlterMethods }  path="/app/methods" />
           <Route component = { AlterAddress }  path="/app/address" />
           <Route component = { AlterCard }  path="/app/card" />
           <Route component = { AlterCheckout }  path="/app/checkout" />
           <Route component = { Signup }  path="/signup" />
           <Route component = { Signup }  path="/app/signup" />
           <Route component = { Term }  path="/term" />
           <Route component = { Plan }  path="/plan" />
           <Route component = { Methods }  path="/methods" />
           <Route component = { PersonalInformation }  path="/personal" />
           <Route component = { Address }  path="/address" />
           <Route component = { Card }  path="/card" />
           <Route component = { Checkout }  path="/checkout" />
           <Route component = { Properties }  path="/properties" />
           <Route component = { ReadNews }  path="/news/:id" />
           <Route component = { TestFecth }  path="/t" />


          {/*  <Route component = { Usuario }  path="/usuario" /> */}
       </div>
   )
}

export default Routes;