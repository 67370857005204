import React, { useState, useEffect } from 'react'
import Header from './Header'
import api from '../../Services/Api'
import InfiniteScroll from 'react-infinite-scroll-component';
import Image from 'react-bootstrap/Image'
import DOMPurify from 'dompurify';
import Modal from './../Modal/Modal'
import Lottie from 'react-lottie';
import imgLoader from './../../Assets/animations/splash_screen.json'
function News({ data, ...props }) {
  

    const [items, setItem] = useState(data.data)
    const [lastId, setlastId] = useState(data.meta.currentLastId)
    const [openModal, setOpenModal] = useState(false)
    const [dados, setDados] = useState()


    const fetchMoreData = () => {
        // a fake async api call like which sends
        // 20 more records in 1.5 secs
    
        api
        .get(`/publication-api/posts?s=15&tf=timeline_analysis&a=${lastId}`)
        .then((response) => {
            setItem(items.concat(response.data.data))
            setlastId(response.data.meta.currentLastId)
           
        })
        .catch((err) => {
            console.error("ops! ocorreu um erro" + err);
        });

        // setTimeout(() => {
        //     setItem(items.concat(Array.from({ length: 20 })))
           
            

        // }, 1500);

        
      };

      const ViewModalClick = (e) =>{
          if(!e.obfuscate){
            setDados(e)
            setOpenModal(true)
          }else{
            setDados(e)
            setOpenModal(false)
          }
    
      }


    return (
        <div className="clientWidget">
            <Header title="Análises" color="blue" />


                 <Modal open ={openModal} close={(e)=> setOpenModal(e)} >
                   <ViewModal data={dados}  />
                </Modal>

          

            <div
                id="scrollableDivAnalysis"
                style={{
                    height: '90%',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#eee'
                }}
                onClick={()=> setOpenModal(true)}
            >
                {/*Put the scroll bar always on the bottom*/}
                {items &&
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchMoreData}
                    style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
                    inverse={false} //
                    hasMore={true}
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="scrollableDivAnalysis"
                >
                    {items.map((_, index) =>  <New data={_} key={index} click={(i)=> ViewModalClick(i)}/>)}
                </InfiniteScroll>
                }
            </div>

        </div>
    )
}



function New({data, click}){
 console.log()

    const HeaderNew =()=><div style={{marginBottom:10}} onClick={() => click(data)}>  <Image src={data.profileImage} style={{margin:5}} roundedCircle width={30} height={30}/>
    {data.name} - {new Date(data.creationDate).toLocaleString()}</div>


    return <div className={`widgerPostAnalysis ${data.obfuscate ? "obfuscate": ""}`}>
       <HeaderNew />
       <div onClick={() => click(data)}><p>{data.content.title}</p></div>
       {data.content?.media_type == "video" && <video width="100%" height="auto"  controls onClick={() => click(data)}>
  <source src={data.content?.media} type="video/mp4"/>
Your browser does not support the video tag.
</video>}
       {data.content?.media_type == "image" &&  <div onClick={() => click(data)}> <Image src={data.content?.media} fluid /></div>}

       <Footer />
    </div>
}







function ViewModal({data, openModal, closeModal}){

    if(!data) return <div/>



    const HeaderNew =()=><div style={{marginBottom:10}}>  <Image src={data.profileImage} roundedCircle width={30} height={30}/>
    {data.name} - data</div>

    return <div className="widgerPostAnalysisModal">
       <HeaderNew />
       <div><h6>{data.content?.title}</h6></div>
       {data.content?.media_type == "video" && <video width="100%" height="auto"  controls>
  <source src={data.content?.media} type="video/mp4"/>
Your browser does not support the video tag.
</video>}
       {data.content?.media_type == "image" &&  <div> <Image src={data.content?.media} fluid /></div>}

      
       <Footer />
       <div  dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.content?.body)}}></div>

    </div>

}



function Footer({data}){
    return <div style={{marginBottom:10}}></div>
}


function GetInitial(props) {
    const [dados, setDados] = useState()

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: imgLoader,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    

    useEffect(() => {
        api
            .get("/publication-api/posts?s=15&tf=timeline_analysis")
            .then((response) => setDados(response.data))
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);
    if (!dados) return <div className="clientWidget">
         <div>
        <Lottie 
          options={defaultOptions}
          height={400}
          width={400}
        />
      </div>
    </div>

    return <News data={dados} props />

}

export default GetInitial
