import React, { useEffect, useState } from 'react';
import useFetch from 'react-fetch-hook';

const Component = () => {
   const [isClicked, setIsClicked] = useState(false);
   const [message, setMessage] = useState('');

   // place the useFetch hooks here as the rules says
   const { isLoading, data } = useFetch(
      'https://portal.datagro.com/gpb/config/get.php',
  
   );

   useEffect(() => {
      isClicked && setIsClicked(false);
   },[isClicked]);

   useEffect(() => {
      if (isLoading) {
         setMessage('Loading ...');
      }
      if (data && !isLoading) {
         setMessage(`Received id: ${data.disclaimer}`)
      }
   },[data, isLoading])

   return (
      <div>
         <button onClick={() => setIsClicked(true)}>Load Data</button>
         <div>{message}</div>
      </div>
   );
}

export default Component