
import React, { useState, useEffect } from 'react'
import Sidebar from './../Components/Sidebar'
import {Navbar, Container, NavDropdown, Nav} from 'react-bootstrap'
import Form from './../Screens/Profile/Form'
import Properties from './../Screens/Profile/Properties'
import Plan from './../Screens/Plans/Plans'
//import Neg from './../Screens/Profile/Negociacao'

import Neg from './../Screens/Negociocion'


import { useAuth } from "../contexts/AuthContext"
export default function Profile() {
  const [childen, setChilden] = useState(<Form />)
  const { login, currentUser, auth, logout } = useAuth()
  return (
    <div>
      
     <NavBar select={(a)=> setChilden(a)} logout={()=> logout()}/>
    <div style={{display: 'flex'}}>

      
     
      <div style={{width: '100%'}}>
        {childen}
      </div>
    </div></div>
  )
}


const NavBar =({select, logout}) => <Container>
  <Navbar  bg="light" expand="lg">
    <Container>
       <Navbar.Brand href="#">Perfil</Navbar.Brand> 
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto">
        <Nav.Link href="#home" onClick={()=> select(<Form />)}> Meus Dados </Nav.Link>
        <Nav.Link href="#plan" onClick={()=> select(<Plan />)}>Meu Plano</Nav.Link>
        <Nav.Link href="#prop" onClick={()=> select(<Properties />)}>Propriedades</Nav.Link>
        {/* <Nav.Link href="#neg"  onClick={()=> select(<Neg />)}>Negociações</Nav.Link>  */}

        {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
          <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
        </NavDropdown> */}
      </Nav>
    </Navbar.Collapse>
    <Navbar.Collapse className="justify-content-end">
      <Navbar.Text>
         <a href="/" onClick={()=> logout('f')}>Sair</a>
      </Navbar.Text>
    </Navbar.Collapse>
    </Container>
  </Navbar>
</Container>
