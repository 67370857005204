import React, { useState, useEffect } from 'react'
import './HeaderCurrencies.css'

import { formatFloat, formatDate } from '../../vdl'
import ReactLoading from 'react-loading';




function HeaderCurrencies({ data }) {
    return (
        <div className='headerFieldsContainer'>
            {data && data.map(function (a, i) {
                return (
                    <ShownCurrencies a={a} />
                )
            })}
        </div>
    )
}






 function ShownCurrencies({ a }) {

    const [quote, setQuote] = useState(a.dados)



    let nome = a.dados.nome.split(" ");

    let variationIcon = quote.var === 0 ? 'minus' : (quote.var < 0 ? 'caret-down' : 'caret-up')
    let variationIconClassName = `variationIcon ${quote.var === 0 ? 'variationIconZero' : ''}`
    let variationClassName = quote.var === 0 ? 'zero' : (quote.var < 0 ? 'down' : 'up')
    let variationFinalClassName = `variation ${variationClassName}`
    let varPrice = `pField ${variationClassName}`

    // useEffect(() => {
    //     if (quote.nome != undefined)
    //         nome = quote.nome.split(" ");
    //     variationIcon = quote.var === 0 ? 'minus' : (quote.var < 0 ? 'caret-down' : 'caret-up')
    //     variationClassName = quote.var === 0 ? styles.zero : (quote.var < 0 ? styles.down : styles.up)
    //     variationFinalClassName = `${styles.pField} ${variationClassName}`


    // }, [quote])







    return (

        <div className='headerField'>
            <p className='pTitle'>{nome[0]}</p>
            <span className={varPrice}> {formatFloat(quote.ult, quote.decimais)}</span>
            {/*
             <p className={variationFinalClassName}><QuoteUlt quote={formatFloat(quote.ult, quote.decimais)}/> <Icon  name={variationIcon} /></p> */}

        </div>

    )
}


function LoadingCurrencies() {

    const [quote, setQuote] = useState();

    useEffect(() => {
        async function getNews(){
            let response = await fetch(`https://precos.api.datagro.com/quadros/index.php?ativos=DOLCM,EURCM&cliente=a81574eb-06c1-11ed-bcba-02ebefa3b928`);
             
            
           let resJson = await response.json();
           console.log(resJson.ativos);
        setQuote(resJson.ativos)
        }
        getNews()
    }, []);

if(quote) return <HeaderCurrencies data={quote} />
return   <ReactLoading type={'spokes'} color={'#000'} height={'10%'} width={'10%'} />
}

// function parseData(data) {
//     if (data.ativos)
//         return data.ativos
//     else return {}
// }

export default LoadingCurrencies
