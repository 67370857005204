import React, { useState, useEffect } from 'react'
import Modal from './../Components/Modal/Modal'
import ModalAuth from './../Components/User/AuthModal'
import News from './../Components/Home/News'
import Negotiations from './../Components/Home/Negotiations'
import Analysis from './../Components/Home/Analysis'
import  './user.css'
import { useAuth } from "../contexts/AuthContext"
export default function User() {
    const [openModal, setOpenModal] = useState(false)
 
    const { login, currentUser, auth } = useAuth()

    if(!currentUser) return <div />

    return (
        <div >
            {/* <h1>User</h1>

                <Modal open ={openModal} close={(e)=> setOpenModal(e)} >
                    <ModalAuth />
                </Modal>
                <button onClick={()=> setOpenModal(true)}>Open Modal</button> */}
            <div className="clientBox">
    
           <Negotiations />
           <Analysis />
           <News />    
 </div>




        </div>
    )
}
