import React, { useState, useEffect } from 'react'
import { Form, Button, InputGroup, Col, FloatingLabel, Row, Container } from 'react-bootstrap'
import api from './../../Services/Api'
import { useAuth } from "../../contexts/AuthContext"


import Loading from '../../Components/Widgets/Loading'

    import { Link, useHistory } from "react-router-dom"

export  default function Index({next}) {
    const { addUser, info } = useAuth()
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(true)

   const history = useHistory()



    
   useEffect(() => {
      
    const get = () =>{
        
        setName(info.name)
        setEmail(info.email)
        setPhone(info.phone)
        setLoading(false)
    }   
    get()

   }, [info])
    


    const handleSubmit = (event) => {
     
        setLoading(true)
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
        
          event.stopPropagation();
        setLoading(false)

          return null
        }else{
            console.log('eee')
            var data = {
                "name": name,
                "email": email,
                "password": password,
                "phone": phone,
                "profileImage": "",
                "role": "Cliente",
                "status": "Ativo",
            };
    
            api
                .put(`/user-api/users/${info.id}`, data)
                .then((response) => {
                    console.log(response)
                   // response.data.data['pwd'] = password
                    //addUser(response.data.data)
                    
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
    
           
            setValidated(true);
            setLoading(false)
        }

       

    }



    if(loading) return <Loading />

    function mascararTel(v){
        v=v.replace(/\D/g,"");            
        v=v.replace(/^(\d{2})(\d)/g,"($1) $2"); 
        v=v.replace(/(\d)(\d{4})$/,"$1-$2");   
        setPhone(v)
        
    }

    return (
        <div style={{
            height: 800,
            maxWidth: 500,
            textAlign: 'center',
            margin:' 0 auto'
        }}>
            
            
            <div style={{fontSize: 30, fontWeight: 'bold', margin: 30}}> 
                Dados Pessoais
            </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Container>
           
          
                <FloatingLabel controlId="floatingSelectGrid" label="Nome Completo" className="g-2 vld-row"> 
                        <Form.Control size="sm" type="text"  required onChange={(e)=> setName(e.target.value)} value={name}  />
                </FloatingLabel>

                <FloatingLabel controlId="floatingSelectGrid" label="Email" className="g-2 vld-row"> 
                        <Form.Control size="sm" type="email"  required onChange={(e)=> setEmail(e.target.value)}  value={email} disabled />
                </FloatingLabel>
                <FloatingLabel controlId="floatingSelectGrid" label="Telefone" className="g-2 vld-row" id="phone"> 
                        <Form.Control size="sm" type="text" value={phone}  required onChange={(e)=> mascararTel(e.target.value)}   />
                </FloatingLabel>
            
                </Container>
                <Button type="submit"  variant="success" style={{width: '95%'}}  className="btn-dl-green"  >Salvar</Button>
            </Form>
   


        </div>
    )
}
