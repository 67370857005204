import React from "react";
import { CDBContainer, CDBSidebar, CDBSidebarContent, CDBSidebarHeader, CDBSidebarMenu, CDBSidebarMenuItem, CDBSidebarFooter } from "cdbreact";

import Form from './../Screens/Profile/Form'
import Properties from './../Screens/Profile/Properties'
import Plan from './../Screens/Profile/Plans'
import Neg from './../Screens/Negociocion'

import {
  
  Link,useHistory
     
   } from "react-router-dom";

import { useAuth } from "../contexts/AuthContext"

const SideBar = ({select}) => {
  const { login, currentUser, auth, logout } = useAuth()
  const { push } = useHistory()
      const setLogout = () =>{
          logout()
          window.location.reload(false);
      }
    return (
         
            <CDBSidebar
              textColor="#fff"
              backgroundColor="#000"
              className="sidebar"
            >
              <CDBSidebarHeader
                prefix={
                  <i className="fa fa-bars" />
                }
              >
                Balizador
              </CDBSidebarHeader>

              <CDBSidebarContent>
                <CDBSidebarMenu>

                  <CDBSidebarMenuItem
                    onClick={()=> select(<Form />)}
                    icon="th-large"
                  >
                    Meus Dados 
                  </CDBSidebarMenuItem>


                  <CDBSidebarMenuItem
                    icon="sticky-note"
                    onClick={()=> select(<Plan />)}

                  >
                    Meu Plano
                  </CDBSidebarMenuItem>


                 
                  <CDBSidebarMenuItem icon="th"  onClick={()=> select(<Properties />)}>Propriedades</CDBSidebarMenuItem>
              
                
               
                  <CDBSidebarMenuItem icon="th" onClick={()=> select(<Neg />)}>Negociações</CDBSidebarMenuItem>
               

                
                  <CDBSidebarMenuItem icon="th">Ajuda</CDBSidebarMenuItem>
               

                </CDBSidebarMenu>


              

              </CDBSidebarContent>

              <CDBSidebarFooter style={{ textAlign: "center" }}>
                <div
                  className="sidebar-btn-wrapper"
                  style={{
                    padding: "20px 5px"
                  }}
                  onClick={() => setLogout()}
                >
                  Sair
                </div>
              </CDBSidebarFooter>
            </CDBSidebar>
         
    );
};

export default  SideBar;
