import React, {useState} from 'react';

import './Header.scss'
import { Link } from 'react-router-dom';


import Currencies from './HeaderCurrencies'
import Auth from '../User/Auth'; 
import Clear from '../../Clear'

import imgLogo from "./../../Assets/logo-header.png"
import imgLogoMd from "./../../Assets/logo-no-text.png"
import background from "./../../Assets/background-header.png"



function Header(config) {
  const {networks} = config.config

 
  return (
    <div className="cHeader" style={{ backgroundImage:  `url(${background})`}}>  
     <Link to={'/'} >
      <div className='logo'>
        <img src={'https://portal.datagro.com/gpb/config/imagens/logos/logo-header.png'} className="logoDatagro" />
        <img src={'https://portal.datagro.com/gpb/config/imagens/logos/logo-header.png'} className="logoDatagroMd" />
      </div>
      </Link>
      
      <div>
{ networks && networks.map(function (item) {
    return   <a href={item.url} target='_blank' class={`fa fa-${item.icon}`}></a>
})}
      </div>

     

      <div className='QuoteDolar'>
            <Currencies />
      </div>
      <Auth />

    
    </div>
  );
}

export default Header;