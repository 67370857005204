import React, { useState, useEffect } from 'react'
import { Form, Button, InputGroup, Col, FloatingLabel, Row, Container } from 'react-bootstrap'

import api from './../../Services/Api'
import { useAuth } from "../../contexts/AuthContext"

import { isValidCPF } from "./../../Utils/VDL"
import './Plan.css'
import { Link, useHistory } from "react-router-dom"


export default function Address({ next }) {
    const { currentUser, getInfo, info, setPlan } = useAuth()
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [validated, setValidated] = useState(false);

    //form
    const [state, setState] = useState()
    const [city, setCity] = useState()
    const [zipcode, setZipcode] = useState()
    const [street, setStreet] = useState()
    const [neighborhood, setNeighborhood] = useState()
    const [streetNumber, setStreetNumber] = useState()
    const [complementary, setComplementary] = useState()

    const [error, setError] = useState({})
    const history = useHistory()


    // /user-api/address/$zipcode



    useEffect(() => {
        console.log(currentUser, info)

        const getState = () => {
            api
                .get(`/user-api/states?s=120`)
                .then((response) => {
                    console.log(response.data);
                    setStates(response.data.data)
                    setState(response.data.data[0]?.id)
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
            // 
        }
        getState()
    }, [])



    useEffect(() => {
        const getCities = () => {
            api
                .get(`/user-api/states/${state}/cities?s=10000`)
                .then((response) => {
                    console.log(response.data);
                    setCities(response.data.data)
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                });
        }
        if (state) getCities()
    }, [state])


    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {

            event.stopPropagation();
        }


        let sUf = states.find(item => item.id === state)

        let address = {
            zipcode: zipcode,
            uf: `${sUf.name} - ${sUf.abbr}`,
            city: cities.find(item => item.id === city).name,
            street: street,
            neighborhood: neighborhood,
            street_number: streetNumber,
            complementary: complementary,
        }

        let user = info
        var data = {
            "id": user.id ?? "",
            "name": user.name ?? "",
            "phone": user.phone ?? "",
            "password": user.password ?? "",
            "email": user.email ?? "",
            "cpf": user.cpf ?? "",
            "profileImage": user.profileImage ?? "",
            "role": user.role ?? "Cliente",
            "status": user.status ?? "Ativo",
            "backgroundImage": user.backgroundImage ?? "",
            "address": address
        };

        // put('/user-api/users/$id', data: data)

        api
            .put(`/user-api/users/${currentUser.user.id}`, data)
            .then((response) => {
                console.log(response.data.data)
                let temp = response.data.data
                let aa = { ...user, ...temp }
                getInfo()
                history.push("/app/card")

                // "data": {
                //     "termId": "XbuAEH1BFoqtDYBmSTOt",
                //     "date": "2021-10-06T12:06:08+00:00",
                //     "id": "xrV47Bm9ZtmikaPLuX1q",
                //     "creationDate": "2021-10-06T13:06:09.3530213+00:00",
                //     "updateDate": "2021-10-06T13:06:09.3530223+00:00"
                // },

            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
                history.push("/app/card")
            });

        setValidated(true);
    }




    useEffect(() => {
        const getCities = () => {
            api
                .get(`/user-api/address/${zipcode}`)
                .then((response) => {
                    console.log(response.data);
                    setError({ ...error, 'cep': false })

                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro" + err);
                    setError({ ...error, 'cep': true })
                });
        }
        if (zipcode && zipcode.length == 8) getCities()

    }, [zipcode])



    return (
        <div className="signup-content">
            <div className="signup-title">
                Endereço
            </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Container>
                    <Row className="g-2">
                        <Form.Group as={Col} md="5" controlId="validationFormik01">
                            <FloatingLabel controlId="floatingSelectGrid" label="CEP" className="g-2 vld-row">
                                <Form.Control size="sm" type="text" required isValid={false} value={zipcode} isInvalid={error.cep && error?.cep == true ? true : false || zipcode=='' ? true : false} onChange={event => setZipcode(event.target.value.replace(".", "").replace(".", "").replace("-", ""))} />
                                <Form.Control.Feedback type="invalid">
                                    Digite um CEP válido
                                </Form.Control.Feedback>
                            </FloatingLabel>

                        </Form.Group>
                    </Row>
                    <Row className="g-2 vld-row">

                        <Col xs={5} >
                            <FloatingLabel controlId="floatingSelectGrid" label="Estado / UF" >
                                <Form.Select aria-label="Estado /UF" size="sm" onChange={(e) => setState(e.target.value)} required >
                                    {states.map((_, key) => <option value={_.id}>{_.name} - {_.abbr}</option>)}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Col >
                            <FloatingLabel controlId="floatingSelectGrid" label="Cidade" >
                                <Form.Select aria-label="Cidade" size="sm" onChange={(e) => setCity(e.target.value)} required>
                                    {cities.map((_, key) => <option value={_.id}>{_.name}</option>)}
                                    {/* {cities.length == 0 &&<option>Seleciona um estado</option>}                           */}
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                    </Row>

                    <Row className="g-2">
                    <Col >
                    <FloatingLabel controlId="floatingSelectGrid" label="Logradouro" className="g-2 vld-row">
                        <Form.Control size="sm" type="text" required onChange={(e) => setStreet(e.target.value)} />
                    </FloatingLabel>
                        </Col>
                        <Col xs={4}>
                             <FloatingLabel controlId="floatingSelectGrid" label="Nº" className="g-2 vld-row">
                                <Form.Control size="sm" type="text" required onChange={(e) => setStreetNumber(e.target.value)} />
                            </FloatingLabel>
                        </Col>
                    </Row>

                  

                 
                            <FloatingLabel controlId="floatingSelectGrid" label="Bairro" className="g-2 vld-row">
                                <Form.Control size="sm" type="text" required onChange={(e) => setNeighborhood(e.target.value)} />
                            </FloatingLabel>
                        
                        
                   
                    <FloatingLabel controlId="floatingSelectGrid" label="Complemento" className="g-2 vld-row">
                        <Form.Control size="sm" type="text" onChange={(e) => setComplementary(e.target.value)} />
                    </FloatingLabel>

                    <Button type="submit" className="btn-dl-green">Próximo</Button>
                </Container>

            </Form>
        </div>
    )

}
