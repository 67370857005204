import axios from "axios";
import { getToken } from "../contexts/AuthContext"



const api = axios.create({
  baseURL: "https://api.gpb.datagro.com",
});

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error

  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  // Do something with response error
  
  return Promise.reject(error);
});


api.interceptors.request.use(async config => {
  // Declaramos um token manualmente para teste.
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;