import React, { useContext, useState, useEffect } from "react"
import { auth } from "../Utils/Firebase"
import api from './../Services/Api'


export const AUTHENTICATION_LOADING = "AUTHENTICATION_LOADING";
export const AUTHENTICATED = "AUTHENTICATED";
export const AUTHENTICATED_ANONYMOUSLY = "AUTHENTICATED_ANONYMOUSLY";
export const UNAUTHENTICATED = "UNAUTHENTICATED";
export const AUTHENTICATION_FAILED = "AUTHENTICATION_FAILED";
const AuthContext = React.createContext()


export function useAuth() {
  return useContext(AuthContext)
}



export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)
  const [splan, setSplan] = useState({})
  const [info, setInfo] = useState({})

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password)
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
  }

  function logout() {
    localStorage.setItem('TOKEN_KEY', '');
    localStorage.setItem('USER', '');
    localStorage.setItem('PLAN', '');
    

    return auth.signOut()
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }


  function setPlan(value) {
    setSplan(value)
    localStorage.setItem('PLAN', JSON.stringify(value));
  }




  function addUser(user) {
    console.log(user)
    setCurrentUser({
      status: AUTHENTICATED,
      user: user,
      token: user.token,
    });
    localStorage.setItem('TOKEN_KEY', user.token);
    localStorage.setItem('USER', JSON.stringify(user));
    return user
  }


function updateDate(u){
  console.log(u)
  if(u){


   let userData = new Date(u.updateDate)
   let data = new Date()
  if(userData < data){
    logout()
  }
   console.log(userData < data) 
   }
}

  
async function getInfo(){
 
    api
    .get(`/user-api/users/info`)
    .then((response) => {
      setInfo(response.data.data)  
      console.log(response.data.data)          
    })
    .catch((err) => {
       
       
});

}






useEffect(() => {
    const get = () =>{
      api
      .get(`/user-api/users/info`)
      .then((response) => {
          setInfo(response.data.data)  
          console.log(response.data.data)          
      })
      .catch((err) => {
          //logout()
         
});
    }

   if(currentUser) get()

}, [currentUser])


  useEffect(() => {
    const unsubscribe =  auth.onAuthStateChanged(async user => {


      try {
        
     

      console.log('unsubscribe entrou')
      if (user) {
        console.log('unsubscribe, user:', user)
        const token = await user.getIdToken();
        const js = await user.toJSON();
        js['id'] = js.id || js.uid


        setCurrentUser({
          status: user.isAnonymous
            ? AUTHENTICATED_ANONYMOUSLY
            : AUTHENTICATED,
          user: js,
          token,
        });

        localStorage.setItem('TOKEN_KEY', token);
        localStorage.setItem('USER', js);

        console.log(token, user, js)
    
        //updateDate(js)
        
      }else{
        let u = localStorage.getItem('USER');
        console.log('unsubscribe, u:', user)
        if(u){
          let token = localStorage.getItem('TOKEN_KEY');

          console.log('unsubscribe, t:', token)
          let uu = JSON.parse(u)
          uu['id'] = uu.id || uu.uid
          console.log('unsubscribe, uu:', uu)
          

          setCurrentUser({
            status:  AUTHENTICATED,
            user: uu,
            token,
           
          });
          //updateDate(uu)
         

        
        }

      

      }
  
    
      setLoading(false)
    } catch (error) {
      logout()
        return null
    }
    })

    return unsubscribe
  }, [])



  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    auth,
    addUser,
    setPlan,
    splan,
    info,
    getInfo
  }
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

export const getToken = () => localStorage.getItem('TOKEN_KEY');