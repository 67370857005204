
import React, { useState, useEffect } from 'react'
import { useAuth } from "../../contexts/AuthContext"
import api from '../../Services/Api'
import { Form, Button, InputGroup, Col, FloatingLabel, Row, Container, Alert, Image } from 'react-bootstrap'
import { Confirm, CustomDialog, useDialog, StaticDialog } from 'react-st-modal';
import Loading from '../../Components/Widgets/Loading'
import './Index.scss'
import { Link } from 'react-router-dom';
import Property from './Property'

import imgBalizador from './../../Assets/images/logo-balizador.svg'

export default function Properties() {
    const { currentUser, addUser, splan, login } = useAuth()
    const [loading, setLoading] = useState(false)
    const [properties, setproperties] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [state, setState] = useState()
    const [city, setCity] = useState()
    const [add, setadd] = useState(true)
    const [activities, setActivities] = useState([])
    const [selectActivities, setSelectActivities] = useState([])
    const [pro, setPro] = useState("")
    const [validated, setValidated] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [isOpenPro, setOpenPro] = useState(false);

    const dialog = useDialog();

    useEffect(() => {
        GetPlan()
        
    }, [])

    const GetPlan = () => {
        setLoading(true)
      
        try {
            if(!currentUser.user.id) console.log('error', currentUser.user.id)
            api
            .get(`/user-api/users/${currentUser.user.id || currentUser.user.uid}/properties/`)
            .then((response) => {
                console.log(response.data.data)
                setproperties(response.data.data);
                setLoading(false)
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
                setLoading(false)
            });
        } catch (error) {
            console.error("ops! ocorreu um erro" + error);
            setLoading(false)
        }

       
       
    }



    const remove = (proper) => {
        setOpenPro(true)
        setPro(proper)
       
    }


    const putProperty = () => {
        setLoading(true)
        api
            .delete(`/user-api/users/${currentUser.user.id}/properties/${pro.id}`)
            .then((response) => {
                console.log(response.data.data);
                setOpenPro(false)
               setPro(null)
                setLoading(false)
                GetPlan()
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
                setLoading(false)
            });
            //delete('/user-api/users/$userId/properties/$id'
    }




    if (loading) return <Loading />


  

    return (
        <div className="properties">
           
            <div style={{fontSize: 30, fontWeight: 'bold', margin: 30}}> 
            Minhas Propriedades
            </div>
            {properties.map((_) => {

                return (
                    <div className="properties-card">
                        <Image src={imgBalizador} rounded />
                        <div> <div className="properties-card-name">{_.name}</div>
                            <div className="properties-card-desc"> 
                           <b> Local:</b> {_.city} {_.stateAbbr}</div>
                            <div className="properties-card-desc"> <b>Atividades:</b>  {_.activities.toString()}</div></div>
                        <div>
                            <Button
                             variant="link"
                             style={{fontSize:10, color: "red"}}
                             onClick={() => {
                                remove(_);
                            }}
                            >
                                remover
                            </Button>
                        </div>
                    </div>
                )

            })}

{properties.length === 0 &&  <div style={{ fontSize: 12 }}>Clique abaixo para adicionar sua primeira propriedade</div>}


            <div>
                <StaticDialog
                    isOpen={isOpen}
                    title=""
                    className="CustomDialog"
                    onAfterClose={(result) => {
                        setOpen(false);
                        GetPlan()
                        // do something with dialog result
                    }}
                >
                    {/* see previous demo */}
                    <Property />
                </StaticDialog>
                <Button
                    className="btn-circle btn-xl"
                    onClick={() => {
                        setOpen(true);
                    }}
                    variant="success"
                    
                >
                    +
                </Button>
            </div>
            

            <StaticDialog
                    isOpen={isOpenPro}
                    title=""
                    className="CustomDialog"
                    onAfterClose={(result) => {
                        setOpenPro(false);
                       
                    }}
                >
                    <div className="properties-remover">
                        <p>Tem certeza que deseja excluir a propriedade?</p>
                        <div className="properties-remover-btn">
                        <Button type="button" style={{ backgroundColor:"green", marginTop:20, width:"40%"}} onClick={() => putProperty()} >Sim</Button>
                        <Button type="button" style={{ backgroundColor:"#ccc", marginTop:20, width:"40%"}} onClick={() => setOpenPro(false)}  >Não</Button>
                        </div>
                    </div>
                </StaticDialog>
        </div>
    )
}
