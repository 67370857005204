import React, { useState, useEffect } from 'react'
import Header from './Header'
import api from './../../Services/Api'
import InfiniteScroll from 'react-infinite-scroll-component';
import Image from 'react-bootstrap/Image'
import Lottie from 'react-lottie';
import imgLoader from './../../Assets/animations/splash_screen.json'

import {
  
    Link,useHistory
       
     } from "react-router-dom";
function News({ data, ...props }) {


    const [items, setItem] = useState(data.data)
    const [lastId, setlastId] = useState(data.meta.currentLastId)



    const fetchMoreData = () => {
        // a fake async api call like which sends
        // 20 more records in 1.5 secs
    
        api
        .get(`/publication-api/posts?s=15&tf=timeline_news&a=${lastId}`)
        .then((response) => {
            setItem(items.concat(response.data.data))
            setlastId(response.data.meta.currentLastId)
         
        })
        .catch((err) => {
            console.error("ops! ocorreu um erro" + err);
        });

        // setTimeout(() => {
        //     setItem(items.concat(Array.from({ length: 20 })))
           
            

        // }, 1500);

    
      };
    return (
        <div className="clientWidget">
            <Header title="Notícias" color="red" />

            <div
                id="scrollableDivNews"
                style={{
                    height: '90%',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#ccc'
                }}
            >
                {/*Put the scroll bar always on the bottom*/}
                {items &&
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchMoreData}
                    style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
                    inverse={false} //
                    hasMore={true}
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="scrollableDivNews"
                   
                >
                    {items.map((_, index) => <div><New data={_} key={index}/> <div style={{backgroundColor:"#ccc", height: 3}}> </div> </div> )}
                </InfiniteScroll>
                }
            </div>

        </div>
    )
}



function New({data}){


    const HeaderNew =()=><div style={{marginBottom:10}}>  <Image src={data.profileImage} roundedCircle width={25} height={25} style={{margin:5}}/>
    {data.name} - {new Date(data.creationDate).toLocaleString()}</div>
  

    return   <Link to={`${data.obfuscate ? "": `/news/${data.id}`}`}><div className={`widgerPostNew ${data.obfuscate ? "obfuscate": ""}`}>
     

        <div className="widgerPostNewDivImg">
            <Image src={data.content.media}  className="widgerPostNewImg"/>
        </div>
        <div>
 <HeaderNew />
         
       <div><p>{data.content.title}</p></div>
       <div></div>
        </div>
      
    </div>
    </Link>

}



function GetInitial(props) {
    const [dados, setDados] = useState()

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: imgLoader,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    

    useEffect(() => {
        api
            .get("/publication-api/posts?s=15&tf=timeline_news")
            .then((response) => setDados(response.data))
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }, []);
    if (!dados) return <div className="clientWidget">
    <div>
   <Lottie 
     options={defaultOptions}
     height={400}
     width={400}
   />
 </div>
</div>

    return <News data={dados} props />

}

export default GetInitial
