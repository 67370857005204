

import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import FloatingLabel from "react-bootstrap-floating-label";
import Button from 'react-bootstrap/Button'
import api from './../../Services/Api'
import { useAuth } from "../../contexts/AuthContext"

import {isValidCPF} from "./../../Utils/VDL"
import  './Plan.css'

import { Link, useHistory } from "react-router-dom"
export  default function PersonalInformation({next}) {
    const { currentUser, addUser } = useAuth()

    const [name, setName] = useState('adf')
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [cpf, setCpf] = useState('')
const [error, setError] = useState('')

const history = useHistory()

useEffect(() => {
   const getuser = () => {
       setName(currentUser.user.name)
       setEmail(currentUser.user.email)
       setPhone(currentUser.user.phone)

   } 

   //getuser()
}, [])



    const handleSubmit = (e) => {
        e.preventDefault()

        console.log(isValidCPF(cpf))
        if(isValidCPF(cpf)){
          let us =  currentUser.user
          us.cpf = cpf
         
          addUser(us)
          history.push("/address")
        }else{
            setError('CPF invalido')
        }
        // console.log('eee')
        // var data = {
        //     "name": name,
        //     "email": email,
        //     "password": password,
        //     "phone": phone,
        //     "profileImage": "",
        //     "role": "Cliente",
        //     "status": "Ativo",
        // };

        // api
        //     .post("/user-api/users", data)
        //     .then((response) => {
        //         console.log(response)
                

               

        //     })
        //     .catch((err) => {
        //         console.error("ops! ocorreu um erro" + err);
        //     });


    }

    return (
        <div className="signup-content">
        <div className="signup-title">
            Dados Pessoal
        </div>
        <div style={{maxWidth: 600, minWidth: 350}}>
             <Form noValidate onSubmit={handleSubmit}>
            <FloatingLabel label="Nome Completo: " id="number" className="mb-3 inputClass"  inputClassName="inputClass"  initialValue={name} />
            <FloatingLabel label="Email: "  type="email" id="myLabel" className="mb-3"   inputClassName="inputClass"  onChange={event => console.log(event.target.value) }  initialValue={email} readOnly/>
            <FloatingLabel label="Telefone: "  type="text" id="myLabel" className="mb-3"    onChange={event => setPhone(event.target.value) }  initialValue={phone} />
            <FloatingLabel label="CPF: "  type="text" id="cpf" className="mb-3"  onChange={event => setCpf(event.target.value.replace(".", "").replace(".", "").replace("-", "")) }  initialValue={cpf}/>
            <Button type="submit" disabled={!isValidCPF(cpf)? true:  false} >Próximo</Button>
            </Form>
        </div>
           
        </div>
    )
}

