import firebase from "firebase/app"
import "firebase/auth"

// const app = firebase.initializeApp( {
//   apiKey: "AIzaSyCZhoO0uEquCIatcsEwHniKIY-p6Ziuufk",
//   authDomain: "balizador-gpb-datagro.firebaseapp.com",
//   projectId: "balizador-gpb-datagro",
//   storageBucket: "balizador-gpb-datagro.appspot.com",
//   messagingSenderId: "143623518740",
//   appId: "1:143623518740:web:8d66179b0c3f3d9a70c501",
//   measurementId: "G-2RXFBPBNKV"
//     })
  



const app = firebase.initializeApp( {
  apiKey: "AIzaSyDdH1p8h89hSOS_rGZLMBGQ7HPkkG_QP0g",
  authDomain: "balizador.firebaseapp.com",
  projectId: "balizador",
  storageBucket: "balizador.appspot.com",
  messagingSenderId: "592101094723",
  appId: "1:592101094723:web:5eb7e37f3232c4f6cc2bf6",
  measurementId: "G-QGMEM300KT"
  })


  export const auth = app.auth()
  export default app
  

  // {
  //   "type": "service_account",
  //   "project_id": "balizador-gpb-datagro",
  //   "private_key_id": "6661f42ce256dec10d5e7491bae6d915fe8d75f9",
  //   "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCtGMerGSWz+dKc\n+ZvyuJGLUVxOTBKRYDzlXXBN3jFxGZ8rC7bNuEqBC9zW1YYE7LRYBLe2u6ENtliW\nwN6a+3wDHVfxdl9A6BSUJuOS6tiK/V1/4bZNlIHjU+Vhhfwni/nT0hCgewKnA0EH\nkmd5rH8utLJ6DrLfV886/B5KmQYNUi5eRQJiEMzcGkpIR2SW83MltbQHubbzLJzp\nFWcd9F+ZNRTc4joKHL8WwD4Su3qJceMArTsYhMbahYEr2JQdlAHBPA4EAaM8OwNd\njJ7bH55MUMZ/AEW7ge4fgMefDZuU+6SrS4+ds8PK0Ijmwn2QQBKDnkEPTAPkDeLF\nSfIakS9nAgMBAAECggEAHdzhEa0oeojWo5tSosjh3zm9pYptR6RlTSLWLbDwMbV8\nZtZCBvLM87+CrZOv5+iYYnkJ4t4y9mUnmnaS0QUjC3yEABXucAm6BydAasfWbQAy\nIc5kf7TnxbrjH3SYoI//HWKn2K/wMLUYgoBpFq5CUOH+ypz/khWZnHEDntYQm7px\ncsPobuFixIXBmsbu8hAewRNknvNyQOcrwt2dhYD/Hms9lyN4GIWviucLzqwVQQJm\ne8WwhICRDAukmAcvCQMyupnmqtw3Z60Zt3hrDT3lJOLMyzke7D5hXmZiG9pYCC4o\nvT04/L6VxkmqqFokb8MQRqsem7dmK/091Io+I3b81QKBgQDml2H6PMYZq2vbwB50\nc7PxJDwO0qKSQhYVlxebiqUUpb4/O0Sy0JSZ5XhaWVePojfRiaBASSjM/7596C5s\nAHogk9sgQPyhXp6U1IHjDmMtGynfZxn4jqpynSpYpMHr4RjUrt4tENQ+YcWU1rIK\nyV7EyvR7awA5ECZaakHY+TN39QKBgQDAK5EqbiJ8FP1nVL1LLj08iUFGJi3h5tdp\nJjGLszkW9LlYPldBAzgPQwqU/lk4kvBnNK78o7/KnB+EPV0sSWIc6TZg71f2A91E\n5Ta8zb6vsD3s5oUKRYaabnmbf1/ImMpq7S3KS8POnUGW4Tvv0ZEpdIZgZwnT68VY\n+J4BeepcawKBgETzrYOTWrVgUg9TAohV9M+9wwu3po9PnaT1GkUTj0t+SEZGJcPG\npTjjqjpyDsHPgxtwKPAwMDgknUSvAfqfpeVJ8TNS23gxgAAHD5PiwHtDbSD1homR\nt5wwLPJ2ydoL5E3swFj/eEfzGo7oKDMbhfAYtESXeWiW0LEmpuwHkIFlAoGAKjBm\nal5Li9o/19OuyzaXkxa/EduH7zRyzk7oSbdLLVME7e6h0VAXmqIpTJSameUIil/y\nmtyIWr7z3GBseDCULay1l4p0UTp/9YQtBD5XQtL6hWwGgT7KUlU2tYC9UpjYt6ji\niKshjdbQc4N3WSs1J1jBt6Bnc/MrHGFYrxJI9YkCgYBtd5+Giu2BS/XBfmhCvpQa\n9oesn6PseQezWYgQciAJ29KjIhIerWe2UL58pGdpPr9jGy4jtZciBrAcKLy8PBrR\nij9PgGin7wu2NLVIJRnVFi7Es4W5Sw1Ai75crUq2HmKGvUtZ5TnIrjDQnAKtUqCo\nQ5Ws615MRjyPmj+ZfVggFA==\n-----END PRIVATE KEY-----\n",
  //   "client_email": "firebase-adminsdk-3pmtq@balizador-gpb-datagro.iam.gserviceaccount.com",
  //   "client_id": "117143311841241785743",
  //   "auth_uri": "https://accounts.google.com/o/oauth2/auth",
  //   "token_uri": "https://oauth2.googleapis.com/token",
  //   "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
  //   "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-3pmtq%40balizador-gpb-datagro.iam.gserviceaccount.com"
  //   }

  //   apiKey: "AIzaSyCZhoO0uEquCIatcsEwHniKIY-p6Ziuufk",
  //   authDomain: "balizador-gpb-datagro.firebaseapp.com",
  //   projectId: "balizador-gpb-datagro",
  //   storageBucket: "balizador-gpb-datagro.appspot.com",
  //   messagingSenderId: "143623518740",
  //   appId: "1:143623518740:web:8d66179b0c3f3d9a70c501",
  //   measurementId: "G-2RXFBPBNKV"