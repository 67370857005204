import React,{useState, useEffect} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
// import { Container } from './styles';
import useFetch from 'react-fetch-hook';

function Header({config}) {
    return (
        <Carousel
        infiniteLoop
        showThumbs={false}
        autoPlay={true}
        showStatus={false}
        width="100%"
        dynamicHeight={true}>

            {config.footer && config.footer.map(function(a,i){
                return ( <a href={a.link}>
                    <img src={a.img} />
                  </a>
               )
            }) }
           
        </Carousel>
    );
}

const AutoLoadQuote = ({ location, match }) => {
    const [dados, setDados] = useState()
    const [loading, setLoading] = useState(true)
    const [config, setConfig] = useState()
  
    const [isClicked, setIsClicked] = useState(false);
    const [message, setMessage] = useState('');
  
    const { isLoading, data } = useFetch(
      'https://portal.datagro.com/gpb/config/config.php?type=banner',
       { depends: [isClicked] }
      );

   useEffect(() => {
     setIsClicked(true);
 },[]);

 useEffect(() => {
    if (isLoading) {
       setMessage('Loading ...');
    }
    if (data && !isLoading) {
      setConfig(data);
      setLoading(false)
    }
 },[data, isLoading])
  
    if (!loading)
      return <Header config={config} />
  
    return (
      <h1 ></h1>
    )
  }
export default AutoLoadQuote;