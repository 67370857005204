import React from 'react';

// import { Container } from './styles';

function Disclaimer({config}) {
  return (
    <div className="footerCbio">
    <div style={{   marginTop: 10, marginLeft: 10, width: '100%' }}>
    <div >
    <img src="https://assets.datagro.com/logo.png" width={100} style={{  margin:10,marginBottom: 2, marginTop: 10 }}/>
      <p style={{ fontSize: 10, color: '#333', paddingLeft:10, paddingRight:10 }}>  {config && config.disclaimerDatagro}</p>
    </div>
   
    </div>
 
  </div>
  );
}

export default Disclaimer;