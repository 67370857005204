
import React, { useState, useEffect } from 'react'
import { useAuth } from "../../contexts/AuthContext"
import api from '../../Services/Api'
import { Form, Button, InputGroup, Col, FloatingLabel, Row, Container, Alert, Image } from 'react-bootstrap'
import { Confirm, CustomDialog, useDialog, StaticDialog } from 'react-st-modal';
import Loading from '../../Components/Widgets/Loading'
import './Index.scss'
import {isMobile} from 'react-device-detect';
import Property from './Property'

import {
  
    Link,useHistory
       
     } from "react-router-dom";

export default function Properties() {
    const { currentUser, addUser, splan, login } = useAuth()
    const [loading, setLoading] = useState(false)
    const [properties, setproperties] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [state, setState] = useState()
    const [city, setCity] = useState()
    const [add, setadd] = useState(true)
    const [activities, setActivities] = useState([])
    const [selectActivities, setSelectActivities] = useState([])
    const [pro, setPro] = useState("")
    const [validated, setValidated] = useState(false);
    const [isOpen, setOpen] = useState(false);
    const [isOpenPro, setOpenPro] = useState(false);

    const { push } = useHistory()

    const dialog = useDialog();

    useEffect(() => {
        GetPlan()
        
    }, [])

    const GetPlan = () => {
        setLoading(true)
        api
            .get(`/user-api/users/${currentUser.user.id}/properties/`)
            .then((response) => {
                setproperties(response.data.data);
                setLoading(false)
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
                setLoading(false)
            });
    }



    const remove = (proper) => {
        setOpenPro(true)
        setPro(proper)
       
    }


    const putProperty = () => {
        setLoading(true)
        api
            .delete(`/user-api/users/${currentUser.user.id}/properties/${pro.id}`)
            .then((response) => {
                console.log(response.data.data);
                setOpenPro(false)
               setPro(null)
                setLoading(false)
                GetPlan()
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
                setLoading(false)
            });
            //delete('/user-api/users/$userId/properties/$id'
    }





    async function fin(){
        if(isMobile) {
            const isConfirm = await Confirm(
                // 'You cannot undo this action',
                'Quer voltar para o Aplicativo?'
              );
              if (isConfirm) {
                window.open('balizador://');
                push('/app/plan')
              }else{
               
                push('/app/plan')
              }
        }else{
            push('/')
        }
      
        
    }


    if (loading) return <Loading />


  

    return (
        <div className="signup-content">
        <div className="signup-title">
            Minhas Propriedades
        </div>
        {/* <div className="properties">
            <div className="properties-title">Minhas Propriedades</div> */}

            {properties.map((_) => {

                return (
                    <div className="properties-card">
                        <div> <div className="properties-card-name">{_.name}</div>
                            <div className="properties-card-desc"> Local: {_.city} {_.stateAbbr}</div>
                            <div className="properties-card-desc"> Atividades: {_.activities.toString()}</div></div>
                        <div>
                            <Button
                             variant="link"
                             style={{fontSize:10, color: "red"}}
                             onClick={() => {
                                remove(_);
                            }}
                            >
                                remover
                            </Button>
                        </div>
                    </div>
                )

            })}

{properties.length === 0 &&  <div style={{ fontSize: 12, marginBottom:10 }}>Clique abaixo para adicionar sua primeira propriedade</div>}


            <div>
                <StaticDialog
                    isOpen={isOpen}
                    title=""
                    className="CustomDialog"
                    onAfterClose={(result) => {
                        setOpen(false);
                        GetPlan()
                        // do something with dialog result
                    }}
                >
                    {/* see previous demo */}
                    <Property />
                </StaticDialog>
                <Button
                    className="btn-circle btn-xl"
                    onClick={() => {
                        setOpen(true);
                    }}
                    variant="success"
                    
                >
                    +
                </Button>
            </div>


            {properties.length != 0 && <Button type="button" style={{width: 250, backgroundColor:"green", marginTop:100 }} onClick={()=>fin()} >Finalizar</Button>
            }
          



            <StaticDialog
                    isOpen={isOpenPro}
                    title=""
                    className="CustomDialog"
                    onAfterClose={(result) => {
                        setOpenPro(false);
                       
                    }}
                >
                    <div className="properties-remover">
                        <p>Tem certeza que deseja excluir a propriedade?</p>
                        <div className="properties-remover-btn">
                        <Button type="button" style={{ backgroundColor:"green", marginTop:20, width:"40%"}} onClick={() => putProperty()} >Sim</Button>
                        <Button type="button" style={{ backgroundColor:"#ccc", marginTop:20, width:"40%"}} onClick={() => setOpenPro(false)}  >Não</Button>
                        </div>
                    </div>
                </StaticDialog>
        </div>
    )
}
