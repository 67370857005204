import React, { useState, useEffect } from 'react'
import { useAuth } from "../../contexts/AuthContext"
import { Form, Button, Image, Col, FloatingLabel, Row, Container } from 'react-bootstrap'
import { Confirm, CustomDialog, useDialog, StaticDialog } from 'react-st-modal';
import { Link, useHistory } from "react-router-dom"
import {isMobile} from 'react-device-detect';
import './Index.scss'
import api from './../../Services/Api'
import imgCoin from './../../Assets/images/coin.svg'

const ticketTextCheckout =
    "Usaremos o cpf fornecido para gerar o seu boleto e dar prosseguimento em seu processo de pagamento.\n\nEnquanto seu pagamento não é identificado você terá acesso completo ao plano contratado. Caso dentro de 3(três) dias o pagamento não for identificado, você será automaticamente transferido para o plano gratuito.";
const cardTextCheckout =
    "Ao clicar em Contratar, suas informações serão transmitidas de forma segura para nossa plataforma de pagamento, Pagar.me. A cobrança será feita de forma recorrente na mesma data de contratação, até que seja feito o seu cancelamento.";


export default function Checkout({ next }) {
    const { currentUser } = useAuth()
    const [plan, setPlan] = useState()
    const [transition, setTransition] = useState(false)
    const [res, setRes] = useState()
    const [isOpen, setOpen] = useState(false);
    const [isOpenBoleto, setIsOpenBoleto] = useState(false);
    const [url, setUrl] = useState('')
    const history = useHistory()
    

    useEffect(() => {
        GetInfo()
    }, [])


    async function GetInfo() {
        console.log('user', currentUser.user)

        let plans = localStorage.getItem('PLAN');
        let terms = localStorage.getItem('TERMS');
        setPlan(JSON.parse(plans))
        console.log('plans:', JSON.parse(plans), 'terms:', JSON.parse(terms))


    }




    const handleBnt = () => {

        var data = {
            "userId": currentUser.user.id,
            "planId": plan.planId,
            "ticketId": "",
            "paymentMethod": currentUser.user.methods == "cartao" ? "credit_card" : "boleto"
        };


        api
            .post(`/user-api/subscriptions`, data)
            .then((response) => {
                setRes(response.data.data)
                localStorage.setItem('CHECKOUT', JSON.stringify(response.data.data));
                if (response.data?.data?.boletoUrl) openMyWindow(response.data?.data?.boletoUrl)
                else{
                    setOpen(false);
                    history.push("/properties")
                }
              


            })
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
                setOpen(false);
            });
    }

    const openMyWindow = (url) => {
        setOpen(false);
        console.log(url)
        setUrl(url)
        setIsOpenBoleto(true)
        // console.log(url)
        // history.push("/properties")
        // const newWindow = window.open(url, '_blank', 'noopener,noreferrer').focus()
        // if (newWindow) newWindow.opener = null

    }



    async function fin(){
      
      
            history.push("/properties")
        
      
    }




    if (!plan) return <div>Carregando</div>

    return (
        <div className="signup-content">
            <div className="signup-title">
                Pagamento
            </div>
            <div className="checkout-box">
 
               
                <div> <b>Você está contratando:</b>  {plan.plan?.name}</div>
                <div> <b>Valor:</b> {plan.ticketplan?.price ? plan.ticketplan?.price : plan.plan?.price}</div>

                <div> <b>Forma de Pagamento:</b> {currentUser.user.methods === "cartao"
                    ? 'Assinatura'
                    : 'Pagamento Único'}</div>

<div> <b>Modalidade:</b> {currentUser.user.methods === "cartao"
                    ? 'Cartão de Credito'
                    : 'Boleto Bancário'}</div>

<Button type="button" className="btn-dl-green" onClick={() => setOpen(true)}>{currentUser.user.methods === "cartao"
                ? 'Efetuar Pagamento'
                : 'Gerar Boleto'}</Button>

            </div>

           
            {/* {currentUser.user.methods !== "cartao" && res
                        

                    <button  onclick=" window.open('http://google.com','_blank')"> Google</button>
                } */}


            <StaticDialog
                isOpen={isOpen}
                title=""
                className="CustomDialog"
                onAfterClose={(result) => {
                    setOpen(false);

                    // do something with dialog result
                }}
            >
                {/* see previous demo */}
                <div className="checkout-modal">
                    {currentUser.user.methods === "cartao"
                        ? cardTextCheckout : ticketTextCheckout
                       }

                    <Button type="button" className="btn-dl-green" onClick={() => handleBnt()}>{currentUser.user.methods === "cartao"
                        ? 'Efetuar Pagamento'
                        : 'Gerar Boleto'}</Button>
                </div>
            </StaticDialog>



            <StaticDialog
                isOpen={isOpenBoleto}
                title=""
                className="CustomDialog"
                onAfterClose={(result) => {
                    setIsOpenBoleto(false);

                    // do something with dialog result
                }}
            >
                {/* see previous demo */}
                <div className="checkout-modal">
                    <div>Boleto gerado com sucesso!! Encaminharemos o boleto para seu email.</div>

                    <Button type="button" className="btn-dl-green" onClick={() =>{
                        // window.open(url,'_blank');
                        setIsOpenBoleto(false);
                        fin();

                    }}>Ver Boleto</Button>
                </div>
            </StaticDialog>


        </div>
       
    )
}
